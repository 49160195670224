import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';
import { isGranted } from '../../utils';
import CitySelect from '../CitySelect';

export default class NewsUpdateForm extends Form {

  prepare(form) {
    const formData = new FormData();

    Object.keys(form).forEach(key => {
      formData.set(key, form[key]);
    });

    return formData;
  }

  render() {
    const {
      user,
      errors,
      loading
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        {isGranted(user, 'admin') ? <form onSubmit={this.submit}>
          <Part
            type="text"
            id="head"
            name="head"
            disabled={loading}
            label='Заголовок'
            value={form.head || ''}
            errors={errors}
            onChange={this.change('head')} />

          <Part
            type="textarea"
            id="message"
            name="message"
            disabled={loading}
            label='Текст новости'
            value={form.message || ''}
            errors={errors}
            onChange={this.change('message')} />

          <Part
            type="file"
            id="logo"
            name="logo"
            required={false}
            disabled={loading}
            label='Фон (*.png, *.jpg, 414x472, до 8МБ)'
            accept=".png, .jpg, .jpeg"
            errors={errors}
            onChange={this.change('logo')} />

          <Part
            required={false}
            type="text"
            id="link"
            name="link"
            disabled={loading}
            label='Ссылка'
            value={form.link || ''}
            errors={errors}
            onChange={this.change('link')} />            

          <Part
            required={false}
            component={CitySelect}
            id="city_id"
            name="city_id"
            disabled={loading}
            label='Город'
            value={form.city_id || ''}
            errors={errors}
            onChange={this.change('city_id')} />

          <FormRow>
            <button
              type="submit"
              disabled={loading}
              className="b-button">
              Сохранить
            </button>
          </FormRow>
        </form> : <div></div> }
      </SpinnerOverlay>
    );
  }
}
