import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindConnect } from '../utils';

class PrivateRoute extends Component {
  static propTypes = {
    user: PropTypes.object,
    component: PropTypes.any.isRequired
  };

  static defaultProps = {
    user: null,
    siteId: 0
  };

  render() {
    const {
      user,
      component: WrapComponent,
      siteId,
      ...rest
    } = this.props;

    const callback = props => {
      if (user) {
        return <WrapComponent siteId={siteId} {...props} />;
      }
      return <Redirect to='/login' />;
    };

    return <Route {...rest} render={callback} />;
  }
}

export default bindConnect(state => {
  return {
    user: state.current_user.user
  };
})(PrivateRoute);
