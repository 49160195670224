import * as types from '../actionTypes';

export function doUploadImage(data) {
  return {
    type: types.PRODUCT_IMAGE_CREATE,
    request: {
      url: '/product_image/create',
      method: 'post',
      data
    }
  };
}

export function doRemoveProductImage(id) {
  return {
    type: types.PRODUCT_IMAGE_REMOVE,
    request: {
      url: `/product_image/remove/${id}`,
      method: 'delete'
    }
  };
}

export function doFetchProductImages(id) {
  return {
    type: types.PRODUCT_IMAGE_LIST,
    request: {
      url: `/product_image/list/${id}`
    }
  };
}
