import { bindConnect } from '../../utils';
import ClaimTable from './ClaimTable';
import * as claimActions from '../../redux/actions/claim';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect((state, props) => ({
  loading: Boolean(state.claim_list.pending)
  || !state.claim_list.data
  || Boolean(state.news_remove.pending),
  ...(state.claim_list.data || {}),
  siteId: props.siteId
}), {
  notification: notificationActions.add,
  fetch: claimActions.list,
  remove: claimActions.remove,
  updateState: claimActions.updateState
})(ClaimTable);
