import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';

export default class RestoreForm extends Form {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ])
  };

  render() {
    const {
      loading,
      errors
    } = this.props;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part type="text"
                name="login"
                id="login"
                disabled={loading}
                autoComplete="new-password"
                className="b-input b-input_white b-input__icon b-input__icon_user"
                errors={errors}
                label='Электронная почта'
                value={this.state.form.login || ''}
                onChange={this.change('login')} />

          <FormRow className="b-form__pane">
            <button type="submit"
                    disabled={loading}
                    className="b-button">
              Восстановить пароль
            </button>

            <Link className="menu__link"
                  to="/login">
              Отмена
            </Link>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
