import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Spinner } from '../../firefly/component';
import Table from '../Table';

export default class DraftScanStatList extends Component {
  static propTypes = {
    //notification: PropTypes.func.isRequired,
    //fetch: PropTypes.func.isRequired,
    //filter: PropTypes.object,
    //remove: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    objects: PropTypes.array,
    //meta: PropTypes.object,
    //className: PropTypes.string
  };

  static defaultProps = {
    //loading: true,
    loading: false,
    //objects: [],    
    //filter: {},
    //meta: {},
    className: ''
  };
  
  render() {
    const {
      className,
      loading,
      success,
      objects
    } = this.props;

    if (loading && !success) {
      return <Spinner />;
    }

    const columns = [
      {
        key: 'tovar',
        title: 'Товар'
      },
      {
        key: 'sum',
        title: 'Сумма, руб.'
      },
      {
        key: 'bonussum',
        title: 'Бонус, руб.'
      },
      {
        key: 'created_at',
        title: 'Дата'
      },
      {
        key: t => (
          <div>
            {t.monetaPartner ? t.monetaPartner.codename : 'Energy'}
          </div>
        ),
        title: 'Монета'
      },
      {
        key: t => (
          <Link to={`/partner/${t.draftProducts.partner_id}`}>
            {t.draftProducts.partner ? t.draftProducts.partner.name : ''}
          </Link>
        ),
        title: 'Партнер'
      }
    ];

    return (
      <div>
        <Table
          className={className}
          columns={columns}
          data={objects} />        
      </div>
    );
  }
}
