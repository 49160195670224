import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';

export default class RegistrationConfirmForm extends Form {
  resend = e => {
    e.preventDefault();

    const {
      resend,
      notification
    } = this.props;

    resend({ id: this.getId() }).then(() => {
      notification({
        title: 'Письмо отправлено'
      });
    });
  };

  getId() {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('id');
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.search);

    this.setState({
      form: { 'id': query.get('id') }
    });
  }

  render() {
    const {
      loading,
      errors
    } = this.props;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part type="token"
                name="token"
                id="token"
                disabled={loading}
                label='Код'
                className="b-input b-input_white b-input__icon b-input__icon_password"
                value={this.state.form.token || ''}
                errors={errors}
                onChange={this.change('token')} />

          <Part type="hidden"
                required={false}
                label=""
                name="id"
                id="id"
                disabled={loading}
                value={this.state.form.id || ''}
                errors={errors}
                onChange={this.change('id')} />

          <FormRow className="b-form__pane">
            <button type="submit"
                    disabled={loading}
                    className="b-button">
              Продолжить
            </button>
            {this.getId() && (
              <button type="b-button"
                      onClick={this.resend}
                      className="link">
                Отправить письмо повторно
              </button>
            )}
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
