import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Transition from '../../component/Transition';
import PartnerLanding from '../../component/PartnerLanding';
//import { Spinner } from '../../firefly/component';

export default class PartnerView extends Component {
  static propTypes = {
    partner: PropTypes.object,
    loading: PropTypes.bool,
    match: PropTypes.object.isRequired
  };

  static defaultProps = {
    user: null,
    loading: true,
    partner: null
  };

  componentDidMount() {
    const {
      fetch
    } = this.props;

    fetch(0);
  }

  render() {
    const {
      //loading,
      partner
    } = this.props;

    return /*loading ? <Spinner /> : */(
      <Transition>
        <PartnerLanding partner={partner} />
      </Transition>
    );
  }
}
