import MonetaImgsList from './MonetaImgsList';
import { doFetchMonetaImages, doRemoveMonetaImage } from '../../redux/actions/moneta_imgs';
import { bindConnect } from '../../utils';

export default bindConnect(state => ({
  loading:
  Boolean(state.moneta_image_list.pending)
  || null === state.moneta_image_list.data
  || Boolean(state.moneta_image_create.pending),
  images: state.moneta_image_list.data || []
}), {
  remove: doRemoveMonetaImage,
  fetch: doFetchMonetaImages
})(MonetaImgsList);
