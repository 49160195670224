import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import DraftscanEditForm from '../../component/DraftscanEditForm';

export default class DraftScanAdd extends Component {
  static defaultProps = {
    objects: null,
    filter: []
  };

  static propTypes = {
    notification: PropTypes.func.isRequired,
  };

  state = {
    success: false,
    moneta: null
  };

  onSuccess = ({ response }) => {

    const {
      notification
    } = this.props;

    notification({
      title: 'Выполнено',
      text: 'Монета сохранена'
    });    
    
    this.setState({
      success: true
    });
  };

  fetch = (id = 0) => {
    const {
      fetch,
      filter
    } = this.props;

    fetch({
      ...filter,
      id
    });
  };  

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId
    } = this.props;
    console.log(objects);

    if ((false === loading && null === objects) || prevProps.siteId !== siteId) {
      fetch();
    }
  }

  render() {

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/draftscan', text: 'Чеки' },
      { text: 'Добавить бонусы за чек' }
    ];

    const {
      success,
      draftscan
    } = this.state;

    if (success) {
      return <Redirect to='/draftscan' />;
    }

    return (
      <Page title='Добавить бонусы за чек' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Добавить бонусы за чек</H1>
          <DraftscanEditForm form={draftscan} onSuccess={this.onSuccess} />
        </Transition>
      </Page>
    );
  }
}
