import * as types from '../actionTypes';

export function list(params) {
  return {
    type: types.DRAFTSCAN_LIST,
    request: { url: '/draftscan/list', params }
  };
}

export function remove(id) {
  return {
    type: types.DRAFTSCAN_REMOVE,
    request: { url: `/draftscan/remove/${id}`, method: 'delete'}
  };
}

export function view(id) {
  return {
    type: types.DRAFTSCAN_VIEW,
    request: { url: `/draftscan/view/${id}` }
  };
}

export function doDraftscanUpdate(data) {
  let id = data instanceof FormData ? data.get('id') : data.id;
  if (id == null) {
    id = 0;
  }
  return {
    type: types.DRAFTSCAN_UPDATE,
    request: { url: `/draftscan/update/${id}`, method: 'post', data }
  };
}

export function doFetchDraftscanOptions(params) {
  return {
    type: types.DRAFTSCAN_OPTIONS,
    request: { url: '/draftscan/options', params }
  };
}

export function stat(data) {
  return {
    type: types.DRAFTSCAN_UPDATE,
    request: { url: `/draftscan/stat`, method: 'post', data }
  };
}

