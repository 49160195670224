import React from 'react';
import { Form } from '../firefly/component';
import Select from 'react-select';

export default class TypeHead extends Form {

  defaultValue = (value,objects) => {
    let val = objects.filter(item =>
      item.value === value
    );
    return val[0] !== undefined ? val[0].label : undefined;
  }

  // Set default props
  static defaultProps = {
    optionsAll: 0
  }  

  render() {

    const {
      value,
      placeholder,
      objects,
      name,
      onChange,
      optionsAll
    } = this.props;

    return (
      <Select
        defaultInputValue={this.defaultValue(value,objects)}
        options={[{value: optionsAll, label: 'Все'}].concat(objects)}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        className="select-container"
      />
    );
  }
}
