import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from '../../component/Page';
import { Hotkey, Hotkeys, HotkeysTarget, Tab, Tabs } from '../../firefly/component';
import OrderTable from '../../component/OrderTable';
import CodeTable from '../../component/CodeTable';
import UserTable from '../../component/UserTable';
import PartnerInfo from '../../component/PartnerInfo';
import { isGranted } from '../../utils';

class Homepage extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  state = {
    tab: 'codes'
  };

  handleQuit = () => {
    console.log('handleQuit');
  };

  handleSave = e => {
    console.log('handleSave');
  };

  renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey label="Quit" combo="q" global onKeyDown={this.handleQuit} />
        <Hotkey label="Save" combo="cmd+s" global onKeyDown={this.handleSave} />
      </Hotkeys>
    );
  }

  handleTabChange = tab => {
    this.setState({ tab });
  };

  render() {
    const breadcrumbs = [
      { icon: 'Home', text: 'Рабочий стол' }
    ];

    const {
      user,
      siteId
    } = this.props;

    const {
      tab
    } = this.state;

    if (isGranted(user, 'admin')) {
      return (
        <Page breadcrumbs={breadcrumbs} title='Рабочий стол'>
          <Tabs onChange={this.handleTabChange} selectedTabId={tab}>
            <Tab id="codes" title="Активированные купоны">
              <CodeTable
                renderColumns={['code', 'amount', 'partner_id', 'user_id', 'updated_at']}
                filter={{ is_active: false }}
                siteId={siteId}
              />
            </Tab>
            <Tab id="orders" title="Новые заказы подарков">
              <OrderTable siteId={siteId} />
            </Tab>
            <Tab id="users" title="Новые пользователи">
              <UserTable renderColumns={['email', 'phone', 'created_at']} sort={4} siteId={siteId} />
            </Tab>
          </Tabs>
        </Page>
      )
    }

    return (
      <Page breadcrumbs={breadcrumbs} title='Рабочий стол'>
        <PartnerInfo/>
        <br/>
        <OrderTable filter={{ partner_id: user.id }} />
      </Page>
    );

  }
}

export default HotkeysTarget(Homepage);
