import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Breadcrumbs } from '../firefly/component';

export default class Page extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    breadcrumbs: PropTypes.array,
    children: PropTypes.any
  };

  static defaultProps = {
    children: null,
    className: '',
    breadcrumbs: []
  };

  render() {
    const {
      title,
      breadcrumbs,
      className,
      children,
      ...rest
    } = this.props;

    return (
      <div className={cx('page', className)} {...rest}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        {children}
      </div>
    );
  }
}
