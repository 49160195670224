import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { H1 } from '../../../firefly/component';
import RegistrationForm from '../../../component/RegistrationForm';
import Page from '../../../component/Page';
import Transition from '../../../component/Transition';

export default class Registration extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  static defaultProps = {
    user: null
  };

  state = {
    success: false,
    email: null
  };
  
  onSuccess = (result) => {
    this.setState({
      id: result.response.data.id,
      success: true
    });
  };

  render() {
    const {
      user
    } = this.props;

    if (user) {
      return <Redirect to='/' />;
    }

    const {
      success,
      id
    } = this.state;

    if (success) {
      return <Redirect to={`/registration/confirm?id=${id}`} />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Регистрация' }
    ];
   
    return (
      <Page title='Регистрация' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Регистрация</H1>
          <RegistrationForm
            search={this.props.location.search}
            onSuccess={this.onSuccess} />
        </Transition>
      </Page>
    );
  }
}
