import { bindConnect } from '../../utils';
import DraftScanAdd from './DraftScanAdd';
import * as notificationActions from '../../redux/actions/notifications';
import * as partnerActions from '../../redux/actions/partner';

export default bindConnect(state => ({
    user: state.current_user.user,
    loading: Boolean(state.partner_balance.pending) || !state.partner_balance.data,
    ...(state.partner_balance.data || { meta: {}, objects: [] })
}), {
    fetch: partnerActions.balance,
    notification: notificationActions.add
})(DraftScanAdd);