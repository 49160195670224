import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Page from '../../component/Page';
import ProductTable from '../../component/ProductTable';
import Transition from '../../component/Transition';
import { isGranted } from '../../utils';

export default class ProductList extends Component {
  render() {
    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Подарки' }
    ];

    const {
      user,
      siteId
    } = this.props;

    const filter = isGranted(user, 'admin') ? {} : {
      partner_id: user.id
    };

    return (
      <Page title='Подарки' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Подарки</H1>
          <div className="toolbar">
            <Link to='/product/create' className="b-button">
              Добавить подарок
            </Link>
            &nbsp;
            <Link to='/product/kassa' className="b-button">
              Выдать подарок
            </Link>
          </div>
          <ProductTable filter={filter} siteId={siteId} />
        </Transition>
      </Page>
    );
  }
}
