import { bindConnect } from '../../utils';
import ContestUpdate from './ContestUpdate';
import * as contestActions from '../../redux/actions/contest';

export default bindConnect(state => ({
  loading: Boolean(state.contest_view.pending) || !state.contest_view.data,
  contest: state.contest_view.data
}), {
  fetch: contestActions.view
})(ContestUpdate);
