import React from 'react';
import { Form } from '../../firefly/component';

export default class SiteSelect extends Form {
  /*UNSAFE_componentWillMount() {
    this.props.fetch();
  }*/

  static defaultProps = {
    name: 'SiteSelect'
  };

  render() {
    /*const {
      //objects,
      name,
      value,
      onChange
    } = this.props;*/

    /*const nodes = objects.map((item, i) => (
      <option key={i} value={item.value}>
        {item.label}
      </option>
    ));*/
    /*{nodes}*/

    return (
      <></>
      /*<select
        className="b-input b-input--select"
        onChange={onChange}
        name={name}
        defaultValue={value}
        id={name}>
        <option value={0}>Арбуз</option>
      </select>*/
    );
  }
}
