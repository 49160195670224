import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import LicenseUseForm from '../../component/LicenseUseForm';

export default class LicenseUse extends Component {

  state = {
    success: false,
    id: undefined,
    lic: undefined
  };

  onSuccess = ({ response }) => {
    this.setState({
      id: response.data.id,
      success: true
    });
  };

  UNSAFE_componentWillMount() {
    const {
      match
    } = this.props;

    this.setState({
      lic: match.params.id
    });    
  }

  render() {
    const {
      success,
      lic
    } = this.state;

    const {
      loading,
    } = this.props;    

    if (success) {
      return <Redirect to={`/licenses`} />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/licenses', text: 'Лицензии' },
      { text: 'Регистрация по лицензии' }
    ];

    return (
      <Page title='Регистрация по лицензии' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Регистрация по лицензии</H1>
          <LicenseUseForm onSuccess={this.onSuccess} id={lic} loading={loading} />
        </Transition>
      </Page>
    );
  }
}
