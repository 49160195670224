import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../firefly/component';
import CountrySelect from './CountrySelect';

export default class CityForm extends Form {
  render() {
    const {
      errors,
      loading
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>

        <Part
            component={CountrySelect}
            id="country_id"
            name="country_id"
            disabled={loading}
            label='Страна'
            value={form.country_id || ''}
            errors={errors}
            onChange={this.change('country_id')} />

          <Part type="text"
                id="name"
                name="name"
                disabled={loading}
                label='Название'
                value={form.name || ''}
                errors={errors}
                onChange={this.change('name')} />

          <FormRow>
            <button type="submit"
                    disabled={loading}
                    className="b-button">
              Сохранить
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
