import { bindConnect } from '../../utils';
import CountryTable from './CountryTable';
import * as countryActions from '../../redux/actions/country';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect(state => ({
  loading: Boolean(state.country_list.pending) || null === state.country_list.data,
  objects: state.country_list.data
}), {
  fetch: countryActions.list,
  remove: countryActions.remove,
  notification: notificationActions.add
})(CountryTable);
