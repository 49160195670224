import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../../firefly/component';
import RestoreForm from '../../../component/RestoreForm';
import Page from '../../../component/Page';
import Transition from '../../../component/Transition';

export default class Restore extends Component {
  state = {
    success: false,
    email: null
  };

  onSuccess = data => {
    this.setState({
      success: true,
      email: data.form.login
    });
  };

  render() {
    const {
      success,
      email
    } = this.state;

    if (success) {
      return <Redirect to={`/restore/confirm?email=${email}`} />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Восстановление пароля' }
    ];

    return (
      <Page title='Восстановление пароля' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Восстановление пароля</H1>
          <RestoreForm onSuccess={this.onSuccess} />
        </Transition>
      </Page>
    );
  }
}
