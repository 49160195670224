import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../../firefly/component';
import RegistrationConfirmForm from '../../../component/RegistrationConfirmForm';
import Page from '../../../component/Page';
import Transition from '../../../component/Transition';

export default class RegistrationConfirm extends Component {
  state = {
    success: false
  };

  onSuccess = () => {
    this.setState({
      success: true
    });

    const {
      notification
    } = this.props;

    notification({
      title: 'Регистрация завершена'
    });
  };

  render() {
    if (this.state.success) {
      return <Redirect to='/' />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/registration', text: 'Регистрация' },
      { text: 'Подтверждение адреса электронной почты' }
    ];

    return (
      <Page title='Подтверждение адреса электронной почты' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Подтверждение адреса электронной почты</H1>
          <div className="text">
            На вашу электронную почту отправлено письмо с кодом подтверждения
            регистрации. Пожалуйста проверьте почту.
          </div>
          <RegistrationConfirmForm
            search={this.props.location.search}
            onSuccess={this.onSuccess} />
        </Transition>
      </Page>
    );
  }
}
