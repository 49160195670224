import { bindConnect } from '../../utils';
import Header from './Header';
import * as authActions from '../../redux/actions/auth';

export default bindConnect((state, props) => ({
  user: state.current_user.user,
  setSiteId: props.setSiteId,
  siteId: props.siteId,
  hideMenu: props.setHideMenu
}), {
  logout: authActions.logout
})(Header);
