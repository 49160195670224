import React, { Component } from 'react';
import { H1 } from '../../firefly/component';
import { Link } from 'react-router-dom';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import MonetsTable from '../../component/MonetsTable';
import { isGranted } from "../../utils";
import CryptoWalletBalances from '../../component/CryptoWalletBalances';

export default class MonetsList extends Component {
  render() {
    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Монеты' }
    ];

    const {
      user,
      siteId
    } = this.props;

    const filter = isGranted(user, 'admin') ? {} : {
      partner_id: user.id
    };

    return (
      <Page title='Монеты' breadcrumbs={breadcrumbs}>
        {isGranted(user, 'admin') ? (<Transition>
          <H1>Монеты</H1>
          <div className="toolbar">
            <Link to='/monets/add' className="b-button">
              Создать
            </Link>
            &nbsp;
          </div>
          <MonetsTable filter={filter} siteId={siteId} />
        </Transition>) : <div></div>}
        <CryptoWalletBalances siteId={siteId} />
      </Page>
    );
  }
}
