import { bindConnect } from '../../utils';
import PartnerView from './PartnerView';
import { doFetchPartner } from '../../redux/actions/partner';

export default bindConnect(state => ({
  loading: Boolean(state.partner_view.pending) || !state.partner_view.data,
  partner: state.partner_view.data
}), {
  fetch: doFetchPartner
})(PartnerView);
