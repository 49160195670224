import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import DraftscanEditForm from '../../component/DraftscanEditForm';
import { Spinner } from '../../firefly/component';

export default class DraftScanUpdate extends Component {

  static propTypes = {
    draftscan: PropTypes.object,
    loading: PropTypes.bool,
    notification: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired
  };
    
  onSuccess = ({ response }) => {

    const {
      notification,
      match
    } = this.props;

    notification({
      title: 'Выполнено',
      text: 'Чек сохранен'
    });    
    this.fetch(match.params.id);
  };

  fetch = (id = 0) => {
    const {
      fetch,
    } = this.props;

    fetch(id);
  };

  componentDidMount() {
    const {
      match
    } = this.props;    
    this.fetch(match.params.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId,
      match
    } = this.props;

    if ((false === loading && null === objects) || prevProps.siteId !== siteId) {
      if (match.params.id !== this.props.match.params.id) {
        fetch(match.params.id);
      }
    }
  }

  render() {

    const {
      loading,
      draftscan,
      match
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/draftscan', text: 'Чеки' },
      { text: 'Настройка чека' }
    ];

    return (
      <Page title='Настройка чека' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Настройка чека</H1>
          {loading ? <Spinner/> : <DraftscanEditForm id={match.params.id} form={draftscan} onSuccess={this.onSuccess} />}
        </Transition>
      </Page>
    );
  }
}
