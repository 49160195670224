import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { requestsPromiseMiddleware } from 'redux-saga-requests';
import reducer from './reducers/index';
import { rootSaga } from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  createLogger(),
  requestsPromiseMiddleware({ auto: true }),
  sagaMiddleware
];

const store = createStore(reducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./reducers', () => {
    /* eslint-disable */
    const nextReducer = require('./reducers').default;
    /* eslint-enable */
    store.replaceReducer(nextReducer);
  });
}

export default store;
