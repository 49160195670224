import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import PartnerUpdateForm from '../../component/PartnerUpdateForm';
import { Spinner } from '../../firefly/component';
import PermissionContainer from '../../component/PermissionContainer';

export default class PartnerUpdate extends Component {
  static propTypes = {
    partner: PropTypes.object,
    loading: PropTypes.bool,
    match: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired
  };

  static defaultProps = {
    loading: true,
    partner: null
  };

  state = {
    success: false,
    id: undefined
  };

  componentDidMount () {
    const {
      match,
      fetch
    } = this.props;

    fetch(match.params.id);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      match,
      siteId,
      fetch
    } = this.props;

    if (prevProps.siteId !== siteId || prevProps.match.params.id !== match.params.id) {
      fetch(match.params.id);
    }
  }

  onSuccess = ({ form }) => {
    this.setState({
      id: form.id,
      success: true
    });
  };

  render() {
    const {
      success,
      id
    } = this.state;

    if (success) {
      return <Redirect to={`/partner/${id}`} />;
    }

    const {
      loading,
      partner,
      siteId
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/partner', text: 'Партнеры' },
      { text: 'Редактирование партнера' }
    ];

    return (
      <Page title='Редактирование партнера' breadcrumbs={breadcrumbs}>
        <PermissionContainer>
          {loading ? <Spinner /> : (
            <Transition>
              <H1>Редактирование партнера</H1>
              <PartnerUpdateForm
                reset={false}
                onSuccess={this.onSuccess}
                form={partner}
                siteId={siteId}
              />
            </Transition>
          )}
        </PermissionContainer>
      </Page>
    );
  }
}
