import {
  persistUser
} from '../../utils';

/**
 * Persist user to local storage and
 * update reducer
 *
 * @param data: { user, jwt }
 * @returns {IterableIterator<*>}
 */
export function* doPersistUser({ data }) {
  yield persistUser(data);
}
