import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import PropTypes from 'prop-types';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import ProductUpdateForm from '../../component/ProductUpdateForm';
import { Spinner } from '../../firefly/component';

export default class ProductUpdate extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    product: PropTypes.object,
    fetch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  static defaultProps = {
    loading: true,
    product: null
  };

  state = {
    success: false,
    id: undefined
  };

  componentDidMount() {
    const {
      match,
      fetch
    } = this.props;

    fetch(match.params.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      siteId,
      fetch,
      match
    } = this.props;

    if (prevProps.siteId !== siteId && match.params.id !== prevProps.match.params.id) {
      fetch(match.params.id)
    }
  }

  onSuccess = ({ form }) => {
    this.setState({
      id: form.id,
      success: true
    });
  };

  render() {
    const {
      success,
      id
    } = this.state;

    if (success) {
      return <Redirect to={`/product/${id}`} />;
    }

    const {
      loading,
      product,
      siteId
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/product', text: 'Подарки' },
      { icon: 'document', text: 'Редактирование подарка' }
    ];

    return (
      <Page title='Редактирование подарка' breadcrumbs={breadcrumbs}>
        {loading ? <Spinner /> : (
          <Transition>
            <H1>Редактирование подарка</H1>
            <ProductUpdateForm
              onSuccess={this.onSuccess}
              form={product}
              siteId={siteId}
            />
          </Transition>
        )}
      </Page>
    );
  }
}
