import React from 'react';
import { Form } from '../../firefly/component';

export default class CitySelect extends Form {

  componentDidMount() {
    const {
      siteId,
      fetch
    } = this.props;    
    fetch({site_id: siteId});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      siteId,
      fetch
    } = this.props;

    if (prevProps.siteId !== siteId) {
      fetch({site_id: siteId})
    }
  }

  render() {
    const {
      objects,
      name,
      value,
      onChange
    } = this.props;

    const nodes = objects.map((item, i) => (
      <option key={i} value={item.value}>
        {item.label}
      </option>
    ));

    return (
      <select
        className="b-input b-input--select"
        onChange={onChange}
        name={name}
        value={value}
        id={name}>
        <option value='0'/>
        {nodes}
      </select>
    );
  }
}
