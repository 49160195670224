import { bindConnect } from '../../utils';
import Menu from './Menu';
import * as authActions from '../../redux/actions/auth';

export default bindConnect((state,props) => ({
  user: state.current_user.user,
  isShowMenu: props.isShowMenu
}), {
  logout: authActions.logout
})(Menu);
