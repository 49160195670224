import * as types from '../actionTypes';

export function list(params) {
  return {
    type: types.COUNTRY_LIST,
    request: { url: '/country', params }
  };
}

export function options(params) {
  return {
    type: types.COUNTRY_OPTIONS,
    request: { url: '/country/options', params }
  };
}

export function view(id) {
  return {
    type: types.COUNTRY_VIEW,
    request: { url: `/country/${id}` }
  };
}

export function create(data) {
  return {
    type: types.COUNTRY_CREATE,
    request: { url: '/country', method: 'post', data }
  };
}

export function update(data) {
  const { id } = data;

  return {
    type: types.COUNTRY_UPDATE,
    request: { url: `/country/${id}`, method: 'post', data }
  };
}

export function remove(id) {
  return {
    type: types.COUNTRY_REMOVE,
    request: { url: `/country/${id}`, method: 'delete' }
  };
}
