import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import LicenseActivateForm from '../../component/LicenseActivateForm';

export default class LicenseActivate extends Component {
  static defaultProps = {
    loading: true,
    objects: null,
    filter: {},
    meta: {},
    className: ''
  };

  state = {
    success: false,
    id: undefined
  };

  onSuccess = ({ response }) => {
    const {
      notification
    } = this.props;

    if (response.data.id === 1) {
      this.setState({
        success: true
      });
      notification({ title: 'Активация выполнена' });

    } else {
      notification({ title: response.data.error });
    }
  };

  fetch = (id = 0) => {
    const {
      fetch,
      filter
    } = this.props;

    fetch({
      ...filter,
      id
    });
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId
    } = this.props;
    console.log(objects);

    if ((false === loading && null === objects) || prevProps.siteId !== siteId) {
      fetch();
    }
  }

  render() {
    const {
      success
    } = this.state;
    const {
      objects,
    } = this.props;
    const balance = objects;
    
    if (success) {
      return <Redirect to={`/`} />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Активация профиля' }
    ];

    return (
      <Page title='Активация профиля' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Активация профиля</H1>
          <div>Баланс {balance !== undefined && balance !== null ? balance['balance'] : '...'} Energy</div>
          <LicenseActivateForm onSuccess={this.onSuccess} />
        </Transition>
      </Page>
    );
  }
}
