import React, { Component } from 'react';
import * as Icons from 'react-feather';
import PropTypes from 'prop-types';
import { H1, SmartDetailTable } from '../../firefly/component';
import { Link } from 'react-router-dom';
import { bindConnect } from '../../utils';
import * as contestActions from '../../redux/actions/contest';

class ContestDetail extends Component {
  static propTypes = {
    contest: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string
    }).isRequired,
    makeWinner: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
  };

  onSelectWinner = id => e => {
    e.preventDefault();

    const {
      makeWinner,
      onSuccess
    } = this.props;

    makeWinner(id).then(() => onSuccess());
  };

  render() {
    const {
      contest
    } = this.props;

    const mapping = [
      {
        title: 'Партнер',
        value: x => (
          <Link to={`/partner/${x.partner_id}`}>
            {x.partner.name}
          </Link>
        )
      },
      {
        title: 'Описание',
        value: 'description'
      },
      {
        title: 'Дата начала',
        value: x => x.start_at ? x.start_at : (
          <Icons.Minus />
        )
      },
      {
        title: 'Дата окончания',
        value: x => x.end_at ? x.end_at : (
          <Icons.Minus />
        )
      },
      {
        title: 'Количество участников',
        value: x => (
          <div>
            {x.count}
          </div>
        )
      },
      {
        title: 'Город',
        value: x => x.city != null ? x.city.name : 'Все'
      },
      {
        title: 'Победитель',
        value: x => x.winner_id ? (
          <Link to={`/user/${x.winner_id}`}>
            {x.winner.email}
          </Link>
        ) : (
          <div>
            {x.count > 0 ? (
              <button
                className='link'
                onClick={this.onSelectWinner(x.id)}>
                Выбрать победителя
              </button>
            ) : <Icons.Minus />}
          </div>
        )
      }
    ];

    const nodes = contest.users.map((user, i) => (
      <Link className='contest__userlink' key={i} to={`/user/${user.id}`}>
        {user.email}
      </Link>
    ));

    return (
      <div>
        <div className="detail-view__panel">
          <H1>{contest.name}</H1>
          <div className='detail-view__buttons'>
            <Link
              className="b-button button--outline"
              to={`/contest/update/${contest.id}`}>
              Изменить
            </Link>
          </div>
        </div>
        <SmartDetailTable
          object={contest}
          mapping={mapping} />
        <div className="divider" />
        <h2 className="heading">Участники</h2>
        {nodes}
      </div>
    );
  }
}

export default bindConnect(null, {
  makeWinner: contestActions.makeWinner
})(ContestDetail);
