import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../firefly/component';

export default class CountryForm extends Form {
  render() {
    const {
      errors,
      loading
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          
          <Part type="text"
                id="name"
                name="name"
                disabled={loading}
                label='Название'
                value={form.name || ''}
                errors={errors}
                onChange={this.change('name')} />

          <FormRow>
            <button type="submit"
                    disabled={loading}
                    className="b-button">
              Сохранить
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
