import * as types from '../actionTypes';

export function list(params) {
  return {
    type: types.SINGLE_CODE_LIST,
    request: { url: '/single_code', params }
  };
}

export function create(data) {
  return {
    type: types.SINGLE_CODE_CREATE,
    request: { url: '/single_code', method: 'post', data }
  };
}

export function update(data) {
  const { id } = data;

  return {
    type: types.SINGLE_CODE_UPDATE,
    request: { url: `/single_code/${id}`, method: 'post', data }
  };
}

export function view(id) {
  return {
    type: types.SINGLE_CODE_VIEW,
    request: { url: `/single_code/${id}` }
  };
}

export function remove(id) {
  return {
    type: types.SINGLE_CODE_REMOVE,
    request: { url: `/single_code/${id}`, method: 'delete' }
  };
}
