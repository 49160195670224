import LicensesList from './LicensesList';
import { bindConnect } from '../../utils';
import { doFetchPartner } from '../../redux/actions/partner';

export default bindConnect(state => ({
  user: state.current_user.user,
  loading: Boolean(state.partner_view.pending) || !state.partner_view.data,
  partner: state.partner_view.data
}), {
  fetch: doFetchPartner
})(LicensesList);