import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Check } from 'react-feather';
import { Link } from 'react-router-dom';
import { Pagination as Pager, Spinner } from '../../firefly/component';
import Table from '../Table';

export default class PromocodeTable extends Component {
  static propTypes = {
    filter: PropTypes.object,
    renderColumns: PropTypes.array,
    fetch: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    objects: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
    className: PropTypes.string
  };

  static defaultProps = {
    className: '',
    renderColumns: [],
    filter: {}
  };
  onPageChange = (page, page_size) => {
    this.fetch(page, page_size);
  };
  onPageSizeChange = page_size => {
    this.fetch(1, page_size);
  };
  fetch = (page = 1, page_size = 10) => {
    const {
      fetch,
      filter,
      siteId
    } = this.props;

    fetch({
      ...filter,
      page_size,
      page,
      site_id: siteId
    });
  };

  qr = (id) => {
    const {
      qr
    } = this.props;

    return qr(id);
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      siteId
    } = this.props;

    if (prevProps.siteId !== siteId) {
      this.fetch()
    }
  }

  render() {
    const {
      renderColumns,
      className,
      loading,
      meta,
      objects
    } = this.props;

    if (loading) {
      return <Spinner />;
    }

    const columns = [
      {
        column: 'code',
        key: 'code',
        title: 'Код'
      },
      {
        column: 'amount',
        key: t => (
          t.amount + " " + (t.moneta != null ? t.moneta.codename : 'Energy')
        ),
        title: 'Сумма'
      },
      {
        column: 'products',
        key: 'products',
        title: 'Подарки'
      },
      {
        column: 'partner_id',
        key: t => (
          <Link to={`/partner/${t.partner_id}`}>
            {t.partner != null ? t.partner.name : ''}
          </Link>
        ),
        title: 'Партнер'
      },
      {
        column: 'usecount',
        key: t => t.usecount,
        title: 'Использован'
      },
      {
        column: 'is_unlimit',
        key: t => t.is_unlimit ? <Check /> : t.counttouse,
        title: 'Можно использовать'
      },
      {
        column: 'updated_at',
        key: t => t.updated_at ? moment(t.updated_at).format('lll') : "-",
        title: 'Дата создания'
      },
      {
        column: 'dateend_at',
        key: t => t.dateend_at ? moment(t.dateend_at).format('lll') : "-",
        title: 'Срок действия'
      },
      {
        column: 'linkqr',
        key: t => (
            <Link to={`/promocode/qr/${t.id}`}>
              QR
            </Link>
        ),
        title: 'QR'
      },
    ];

    return (
      <div>
        <Table
          className={className}
          renderColumns={renderColumns}
          columns={columns}
          data={objects} />
        {!loading && <Pager
          onPageChange={this.onPageChange}
          pageCount={meta.page_count}
          page={meta.page}
          onPageSizeChange={this.onPageSizeChange}
          size={meta.page_size}
          total={meta.total} />}
      </div>
    );
  }
}
