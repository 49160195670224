import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import SingleCodeCreateForm from '../../component/SingleCodeCreateForm';

export default class SingleCodeCreate extends Component {
  state = {
    success: false
  };

  onSuccess = () => {
    this.setState({ success: true });
  };

  render() {
    const {
      success
    } = this.state;

    const {
      siteId
    } = this.props;

    if (success) {
      return <Redirect to='/single_code' />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/single_code', text: 'Реклатные коды' },
      { icon: 'document', text: 'Добавление кода' }
    ];

    return (
      <Page title='Добавление кода' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Добавление кода</H1>
          <SingleCodeCreateForm siteId={siteId} onSuccess={this.onSuccess} />
        </Transition>
      </Page>
    );
  }
}
