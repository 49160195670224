import {
  getRequestInstance,
  sendRequest
} from 'redux-saga-requests';
import {
  put,
  call,
  select
} from 'redux-saga/effects';
import {
  persistUser
} from '../../utils';

import * as notificationActions from '../actions/notifications';
import {
  didUserSet
} from '../actions/user';
import { local } from 'easy-storage';
import { USER_LOCALSTORAGE_KEY } from '../actionTypes';

/**
 * Do sth with you request, like add token to header, or dispatch some action etc.
 *
 * @param request
 * @returns {IterableIterator<*>}
 */
export function* onRequest(request) {
  const currentUser = yield select(state => state.current_user);

  const authHeaders = {
    Authorization: currentUser.jwt
  };

  request.headers = {
    ...(request.headers || {}),
    ...authHeaders
  };

  return request;
}

export function tokenExpired(error) {
  if (error.response && error.response.data) {
    const { statusCode, message } = error.response.data;

    return statusCode === 401 && message === 'jwt malformed';
  }

  return false;
}

export function* onError(error, action) {
  if (error.response) {
    if (error.response.status === 400) {
      yield put(
        notificationActions.add({
          title: 'Ошибка',
          text: 'Проверьте корректность вводимых данных.',
          state: 'error'
        })
      );
    } else if (error.response.status === 404) {
      yield put(
        notificationActions.add({
          title: 'Ошибка',
          text: 'Страница не найдена',
          state: 'error'
        })
      );
    } else if (error.response.status === 401) {
      local.remove(USER_LOCALSTORAGE_KEY);    
      yield put(
        notificationActions.add({
          title: 'Ошибка авторизации',
          text: 'Авторизуйтесь в системе.',
          state: 'error'
        })
      );
    } else if (error.response.status >= 500) {
      yield put(
        notificationActions.add({
          title: 'Ошибка',
          text: 'Внутренняя ошибка. Пожалуйста попробуйте позже.',
          state: 'error'
        })
      );
    }
  }

  if (tokenExpired(error)) {
    // get driver instance, in our case Axios to make a request without Redux
    const requestInstance = yield getRequestInstance();

    try {
      // trying to get a new token
      const currentUser = yield select(state => state.current_user);

      const { data } = yield call(requestInstance.post, '/user/refresh_token', {
        email: currentUser.user.email,
        refresh_token: currentUser.user.refresh_token
      });
      persistUser(data);

      yield put(didUserSet(data));

      // we fire the same request again:
      // - with silent: true not to dispatch duplicated actions
      // - with runOnError: false not to call this interceptor again for this request
      // Return { callback, error }
      return yield call(sendRequest, action, {
        silent: true,
        runOnError: false
      });
    } catch (e) {
      // we didnt manage to get a new token
      return { error: e };
    }
  }

  return { error };
}
