import { bindConnect, getValidationErrors } from '../../utils';
import { doRegistration } from '../../redux/actions/auth';
import RegistrationForm from './RegistrationForm';

export default bindConnect(state => ({
  errors: getValidationErrors(state.registration),
  loading: Boolean(state.registration.pending)
}), {
  callback: doRegistration
})(RegistrationForm);
