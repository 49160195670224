import { bindConnect } from '../../utils';
import SingleCodeTable from './SingleCodeTable';
import * as singleCodeActions from '../../redux/actions/singleCode';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect(state => ({
  loading: Boolean(state.single_code_list.pending) || !state.single_code_list.data,
  ...(state.single_code_list.data || { meta: {}, objects: [] })
}), {
  remove: singleCodeActions.remove,
  notification: notificationActions.add,
  fetch: singleCodeActions.list
})(SingleCodeTable);
