import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import logo2 from '../images/logo_2.svg';
import logo3 from '../images/logo_3.png';

export default class Logo extends Component {
  render() {
    const {
      siteId
    } = this.props;

    return (
      <Link to="/" className="logo__link">
        <img className="logo__image" alt="app" src={parseInt(siteId) === 0 ? logo : (parseInt(siteId) === 1 ? logo2 : logo3)} />
      </Link>
    );
  }
}
