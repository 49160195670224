import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import NewsCreateForm from '../../component/NewsCreateForm';

export default class NewsCreate extends Component {

  state = {
    success: false
  };

  onSuccess = ({ response }) => {
    this.props.notification({ title: 'Выполнено' });

    this.setState({
      id: response.data.id,
      success: true
    });
  };

  render() {

    const { success } = this.state;
    const { siteId } = this.props;
    

    if (success) {
      return <Redirect to={`/news`} />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/news', text: 'Новости' },
      { text: 'Добавление новости' }
    ];

    return (
      <Page title='Добавление новости' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Добавление новости</H1>
          <NewsCreateForm
            onSuccess={this.onSuccess}
            siteId={siteId} />
        </Transition>
      </Page>
    );
  }
}
