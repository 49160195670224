import React, { Component } from 'react';

export default class NoMatch extends Component {
  render() {
    return (
      <div className="b-nomatch">
        404 - Страница не найдена
      </div>
    );
  }
}
