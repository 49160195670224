import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { H1 } from '../../firefly/component';
import { Link } from 'react-router-dom';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import NewsTable from '../../component/NewsTable';
import { isGranted } from '../../utils';

export default class NewsList extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  static defaultProps = {
    user: null
  };

  render() {
    const {
      user,
      siteId
    } = this.props;

    return (
      <Page title='Новости'>
        {isGranted(user, 'admin') ? <Transition>
          <H1>Новости</H1>
          <div className="toolbar">
            <Link to={`/news/create`} className="b-button">
              Добавить новость
            </Link>
            &nbsp;
            <Link to={`/news/newnotif`} className="b-button">
              Создать уведомление
            </Link>
          </div>
          <NewsTable siteId={siteId} />
        </Transition> : <div></div>}
      </Page>
    );
  }
}
