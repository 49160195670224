import React, { Component } from 'react';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import StatReferalsFilter from '../../component/StatReferalsFilter';
import StatReferalsList from '../../component/StatReferalsList';

export default class StatReferals extends Component {

  state = {
    success: false,
    data: undefined
  };

  onSuccess = ({ response }) => {
    this.setState({
      success: true,
      data: response.data
    });
  };

  dateFrom() {
    let d = new Date();
    return d.toISOString();
  }

  defForm() {
    return {
      start_at: this.dateFrom(), 
      end_at: this.dateFrom()
    };
  }

  render() {

    const {
      data,
      success
    } = this.state;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/licenses', text: 'Рефералы' },
      { text: 'Статистика по рефералам' }
    ];

    return (      
      <Page title='Статистика по рефералам' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Статистика по рефералам</H1>
          <StatReferalsFilter form={this.defForm()} onSuccess={this.onSuccess}/>
        </Transition>
        <br/>
        <Transition>          
          {success ? 
            (<StatReferalsList
              objects={data.objects}
              success={success}
            />) : 
            <div></div>
          }
        </Transition>
      </Page>
    );
  }
}
