import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import SingleCodeTable from '../../component/SingleCodeTable';
import PermissionContainer from '../../component/PermissionContainer';

export default class SingleCodeList extends Component {
  render() {
    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Рекламные коды' }
    ];

    const {
      siteId
    } = this.props;

    return (
      <Page title='Рекламные коды' breadcrumbs={breadcrumbs}>
        <PermissionContainer>
          <Transition>
            <H1>Рекламные коды</H1>
            <div className="toolbar">
              <Link to="/single_code/create" className="b-button">
                Создать код
              </Link>
            </div>
            <SingleCodeTable siteId={siteId} />
          </Transition>
        </PermissionContainer>
      </Page>
    );
  }
}
