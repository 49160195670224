import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '../../firefly/component';
import Table from '../Table';

export default class StatReferalsList extends Component {
  static propTypes = {
    //notification: PropTypes.func.isRequired,
    //fetch: PropTypes.func.isRequired,
    //filter: PropTypes.object,
    //remove: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    objects: PropTypes.array,
    //meta: PropTypes.object,
    //className: PropTypes.string
  };

  static defaultProps = {
    //loading: true,
    loading: false,
    //objects: [],    
    //filter: {},
    //meta: {},
    className: ''
  };
  
  render() {
    const {
      className,
      loading,
      success,
      objects
    } = this.props;

    if (loading && !success) {
      return <Spinner />;
    }

    const columns = [
      {
        key: 'Name',
        title: 'Реферал'
      },
      {
        key: 'amount',
        title: 'Сумма, Energy'
      }
    ];

    return (
      <div>
        <Table
          className={className}
          columns={columns}
          data={objects} />        
      </div>
    );
  }
}
