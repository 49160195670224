import * as types from '../actionTypes';

export function doUploadImage(data) {
  return {
    type: types.MONETA_IMAGE_CREATE,
    request: {
      url: '/partner/moneta-image-create',
      method: 'post',
      data
    }
  };
}

export function doRemoveMonetaImage(id) {
  return {
    type: types.MONETA_IMAGE_REMOVE,
    request: {
      url: `/partner/moneta-image-remove/${id}`,
      method: 'delete'
    }
  };
}

export function doFetchMonetaImages(id) {
  return {
    type: types.MONETA_IMAGE_LIST,
    request: {
      url: `/partner/moneta-image-list/${id}`
    }
  };
}
