import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';

export default class ProductKassaForm extends Form {
  
  componentDidMount() {
    const { form } = this.props;
    delete form.user;
    delete form.partner;
    delete form.created_at;
    delete form.updated_at;

    this.setState({ form });
  }

  render() {
    const {
      errors,
      loading      
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part type="text"
                id="code"
                name="code"
                disabled={loading}
                label='Код'
                value={form.code || ''}
                errors={errors}
                onChange={this.change('code')} />

          <FormRow>
            <button type="submit"
                    disabled={loading}
                    className="b-button">
              Запрос
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
