import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../firefly/component';
import PartnerSelect from './PartnerSelect';
import CitySelect from './CitySelect';
import ContestCategSelect from './ContestCategSelect';
import { isGranted } from '../utils';

export default class ContestForm extends Form {
  componentDidMount() {
    const { form } = this.props;
    delete form.image;

    this.setState({ form });
  }

  prepare(form) {
    const formData = new FormData();

    Object.keys(form).forEach(key => {
      formData.set(key, form[key]);
    });

    return formData;
  }

  onChange(year, month, date) {
    this.setState({ year, month, date });
  }

  render() {
    const {
      errors,
      user,
      loading
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part
            type="text"
            id="name"
            name="name"
            disabled={loading}
            label='Название'
            value={form.name || ''}
            errors={errors}
            onChange={this.change('name')} />

          <Part
            type="number"
            id="price"
            name="price"
            min="1"
            disabled={loading}
            label='Стоимость участия'
            value={form.price || ''}
            errors={errors}
            onChange={this.change('price')} />

          <Part
            type="text"
            id="start_at"
            name="start_at"
            placeholder="__.__.____"
            disabled={loading}
            label='Дата начала'
            value={form.start_at || ''}
            errors={errors}
            onChange={this.change('start_at')} />

          <Part
            type="text"
            id="end_at"
            name="end_at"
            placeholder="__.__.____"
            disabled={loading}
            label='Дата окончания'
            value={form.end_at || ''}
            errors={errors}
            onChange={this.change('end_at')} />

          <Part
            type="file"
            id="image"
            name="image"
            required={false}
            disabled={loading}
            label='Изображение (*.png, *.jpg, до 8МБ)'
            accept=".png, .jpg, .jpeg"
            errors={errors}
            onChange={this.change('image')} />

          <Part
            type="textarea"
            id="description"
            name="description"
            disabled={loading}
            label='Описание и условия конкурса'
            value={form.description || ''}
            errors={errors}
            onChange={this.change('description')} />

          <Part
            component={ContestCategSelect}
            required={false}
            id="categ"
            name="categ"
            disabled={loading}
            label='Категория'
            value={form.categ}
            errors={errors}
            onChange={this.change('categ')} />

          <Part
            required={false}
            component={CitySelect}
            id="city_id"
            name="city_id"
            disabled={loading}
            label='Город'
            value={form.city_id || ''}
            errors={errors}
            onChange={this.change('city_id')} />

          {isGranted(user, 'admin') && (
            <Part
              component={PartnerSelect}
              id="partner_id"
              name="partner_id"
              disabled={loading}
              label='Партнер'
              value={form.partner_id || ''}
              errors={errors}
              onChange={this.change('partner_id')} />
          )}

          <FormRow>
            <button
              type="submit"
              disabled={loading}
              className="b-button">
              Сохранить
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
