import * as types from '../actionTypes';

export function list(params) {
  return {
    type: types.PRODUCT_LIST,
    request: { url: '/product/list', params }
  };
}

export function view(id) {
  return {
    type: types.PRODUCT_VIEW,
    request: { url: `/product/view/${id}` }
  };
}

export function create(data) {
  return {
    type: types.PRODUCT_CREATE,
    request: { url: '/product/create', method: 'post', data }
  };
}

export function update(data) {
  const { id } = data;

  return {
    type: types.PRODUCT_UPDATE,
    request: { url: `/product/update/${id}`, method: 'post', data }
  };
}

export function remove(id) {
  return {
    type: types.PRODUCT_REMOVE,
    request: { url: `/product/remove/${id}`, method: 'delete' }
  };
}

export function options(params) {
  return {
    type: types.PRODUCT_OPTIONS,
    request: { url: '/product/options', params }
  };
}

export function kassacode(data) {
  return {
    type: types.PRODUCT_KASSACODE,
    request: { url: '/product/kassacode', method: 'post', data }
  };
}

export function kassavydat(data) {
  return {
    type: types.PRODUCT_KASSAVYDAT,
    request: { url: '/product/kassavydat', method: 'post', data }
  };
}
