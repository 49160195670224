import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { H1, Spinner } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import SingleCodeUpdateForm from '../../component/SingleCodeUpdateForm';

export default class SingleCodeUpdate extends Component {
  static propTypes = {
    code: PropTypes.object,
    loading: PropTypes.bool,
    match: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired
  };

  static defaultProps = {
    loading: true,
    code: null
  };

  state = {
    success: false
  };

  UNSAFE_componentWillMount() {
    const {
      match,
      fetch
    } = this.props;

    fetch(match.params.id);
  }

  UNSAFE_componentWillReceiveProps({ match, fetch }) {
    if (match.params.id !== this.props.match.params.id) {
      fetch(match.params.id);
    }
  }

  onSuccess = () => {
    this.setState({ success: true });
  };

  render() {
    const {
      success
    } = this.state;

    if (success) {
      return <Redirect to='/single_code' />;
    }

    const {
      loading,
      code
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/single_code', text: 'Рекламные коды' },
      { icon: 'document', text: 'Редактирование кода' }
    ];

    return (
      <Page title='Редактирование кода' breadcrumbs={breadcrumbs}>
        {loading ? <Spinner /> : (
          <Transition>
            <H1>Редактирование кода</H1>
            <SingleCodeUpdateForm
              reset={false}
              onSuccess={this.onSuccess}
              form={code} />
          </Transition>
        )}
      </Page>
    );
  }
}
