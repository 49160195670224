/* eslint-disable */
import { combineReducers } from 'redux';
import { requestsReducer } from 'redux-saga-requests';
import * as types from '../actionTypes';

import current_user from './current_user';
import notifications from './notifications';

export default combineReducers({
  current_user,
  notifications,

  registration_confirm: requestsReducer({
    actionType: types.USER_REGISTRATION_CONFIRM
  }),
  restore_confirm: requestsReducer({
    actionType: types.USER_RESTORE_CONFIRM
  }),
  registration: requestsReducer({
    actionType: types.USER_REGISTRATION
  }),
  user_login: requestsReducer({
    actionType: types.USER_LOGIN
  }),
  user_options: requestsReducer({
    actionType: types.USER_OPTIONS
  }),
  user_list: requestsReducer({
    actionType: types.USER_LIST,
    resetOn: [
      types.USER_REMOVE,
      types.USER_CREATE
    ]
  }),
  user_balances: requestsReducer({
    actionType: types.USER_BALANCES
  }),
  user_referals: requestsReducer({
    actionType: types.USER_REFERALS
  }),  
  user_activities: requestsReducer({
    actionType: types.USER_ACTIVITIES
  }),  
  user_view: requestsReducer({
    actionType: types.USER_VIEW
  }),
  user_remove: requestsReducer({
    actionType: types.USER_REMOVE
  }),
  user_create: requestsReducer({
    actionType: types.USER_CREATE
  }),
  user_update: requestsReducer({
    actionType: types.USER_UPDATE
  }),  
  change_password: requestsReducer({
    actionType: types.USER_CHANGE_PASSWORD
  }),
  restore: requestsReducer({
    actionType: types.USER_RESTORE
  }),
  restore_check: requestsReducer({
    actionType: types.USER_RESTORE_CHECK
  }),
  restore_resend: requestsReducer({
    actionType: types.USER_RESTORE_RESEND
  }),
  registration_resend: requestsReducer({
    actionType: types.USER_REGISTRATION_RESEND
  }),

  order_list: requestsReducer({
    actionType: types.ORDER_LIST,
  }),

  city_list: requestsReducer({
    actionType: types.CITY_LIST,
  }),
  city_options: requestsReducer({
    actionType: types.CITY_OPTIONS,
  }),
  city_view: requestsReducer({
    actionType: types.CITY_VIEW,
  }),
  city_create: requestsReducer({
    actionType: types.CITY_CREATE,
  }),
  city_update: requestsReducer({
    actionType: types.CITY_UPDATE,
  }),
  city_remove: requestsReducer({
    actionType: types.CITY_REMOVE,
  }),

  country_list: requestsReducer({
    actionType: types.COUNTRY_LIST,
  }),
  country_options: requestsReducer({
    actionType: types.COUNTRY_OPTIONS,
  }),
  country_view: requestsReducer({
    actionType: types.COUNTRY_VIEW,
  }),
  country_create: requestsReducer({
    actionType: types.COUNTRY_CREATE,
  }),
  country_update: requestsReducer({
    actionType: types.COUNTRY_UPDATE,
  }),
  country_remove: requestsReducer({
    actionType: types.COUNTRY_REMOVE,
  }),

  product_list: requestsReducer({
    actionType: types.PRODUCT_LIST,
    resetOn: [
      types.PRODUCT_CREATE,
      types.PRODUCT_UPDATE,
      types.PRODUCT_REMOVE
    ]
  }),
  product_create: requestsReducer({
    actionType: types.PRODUCT_CREATE
  }),
  product_update: requestsReducer({
    actionType: types.PRODUCT_UPDATE
  }),
  product_view: requestsReducer({
    actionType: types.PRODUCT_VIEW,
    resetOn: [
      types.PRODUCT_UPDATE,
      types.PRODUCT_REMOVE
    ]
  }),
  product_kassacode: requestsReducer({
    actionType: types.PRODUCT_KASSACODE
  }),
  product_kassavydat: requestsReducer({
    actionType: types.PRODUCT_KASSAVYDAT
  }),
  product_image_create: requestsReducer({
    actionType: types.PRODUCT_IMAGE_CREATE
  }),
  product_image_list: requestsReducer({
    actionType: types.PRODUCT_IMAGE_LIST,
    multiple: true
  }),
  product_options: requestsReducer({
    actionType: types.PRODUCT_OPTIONS
  }),

  contest_list: requestsReducer({
    actionType: types.CONTEST_LIST,
    resetOn: [
      types.CONTEST_CREATE,
      types.CONTEST_UPDATE,
      types.CONTEST_REMOVE
    ]
  }),
  contest_create: requestsReducer({
    actionType: types.CONTEST_CREATE
  }),
  contest_update: requestsReducer({
    actionType: types.CONTEST_UPDATE
  }),
  contest_view: requestsReducer({
    actionType: types.CONTEST_VIEW,
    resetOn: [
      types.CONTEST_UPDATE,
      types.CONTEST_REMOVE
    ]
  }),
  contest_remove: requestsReducer({
    actionType: types.CONTEST_VIEW,
    resetOn: [
      types.CONTEST_CREATE,
      types.CONTEST_UPDATE,
      types.CONTEST_REMOVE
    ]
  }),

  code_download: requestsReducer({
    actionType: types.CODE_DOWNLOAD
  }),
  code_list: requestsReducer({
    actionType: types.CODE_LIST,
    resetOn: [
      types.CODE_GENERATE
    ]
  }),
  code_generate: requestsReducer({
    actionType: types.CODE_GENERATE
  }),

  settings_save: requestsReducer({
    actionType: types.SETTINGS_SAVE
  }),
  settings_list: requestsReducer({
    actionType: types.SETTINGS_LIST,
  }),

  poll_list: requestsReducer({
    actionType: types.POLL_LIST,
    resetOn: [
      types.POLL_CREATE,
      types.POLL_UPDATE,
      types.POLL_REMOVE
    ]
  }),
  poll_create: requestsReducer({
    actionType: types.POLL_CREATE
  }),
  poll_update: requestsReducer({
    actionType: types.POLL_UPDATE
  }),
  poll_remove: requestsReducer({
    actionType: types.POLL_REMOVE
  }),
  poll_view: requestsReducer({
    actionType: types.POLL_VIEW,
    resetOn: [
      types.POLL_CREATE,
      types.POLL_UPDATE,
      types.POLL_REMOVE
    ]
  }),

  option_view: requestsReducer({
    actionType: types.OPTION_VIEW,
    resetOn: [
      types.OPTION_UPDATE,
      types.OPTION_REMOVE
    ]
  }),
  option_list: requestsReducer({
    actionType: types.OPTION_LIST,
    resetOn: [
      types.OPTION_CREATE,
      types.OPTION_UPDATE,
      types.OPTION_REMOVE
    ]
  }),
  option_create: requestsReducer({
    actionType: types.OPTION_CREATE
  }),
  option_update: requestsReducer({
    actionType: types.OPTION_UPDATE
  }),
  option_remove: requestsReducer({
    actionType: types.OPTION_REMOVE
  }),

  partner_list: requestsReducer({
    actionType: types.PARTNER_LIST,
    resetOn: [
      types.PARTNER_CREATE,
      types.PARTNER_UPDATE,
      types.PARTNER_REMOVE
    ]
  }),
  partner_options: requestsReducer({
    actionType: types.PARTNER_OPTIONS,
    multiple: true,
    resetOn: [
      types.PARTNER_CREATE,
      types.PARTNER_UPDATE,
      types.PARTNER_REMOVE
    ]
  }),
  partner_view: requestsReducer({
    actionType: types.PARTNER_VIEW,
    resetOn: [
      types.PARTNER_UPDATE,
      types.PARTNER_REMOVE
    ]
  }),
  partner_create: requestsReducer({
    actionType: types.PARTNER_CREATE
  }),
  partner_update: requestsReducer({
    actionType: types.PARTNER_UPDATE
  }),
  partner_delete: requestsReducer({
    actionType: types.PARTNER_REMOVE
  }),
  partner_balance: requestsReducer({
    actionType: types.PARTNER_BALANCE,
    resetOn: [
      types.LICENSE_BYE,
      types.LICENSE_LIST,
      types.PARTNER_BALANCEADD,
      types.PARTNER_BALANCEPAYDONE
    ]
  }),
  patner_balanceadd: requestsReducer({
    actionType: types.PARTNER_BALANCEADD,
  }),

  single_code_list: requestsReducer({
    actionType: types.SINGLE_CODE_LIST
  }),
  single_code_create: requestsReducer({
    actionType: types.SINGLE_CODE_CREATE
  }),
  single_code_update: requestsReducer({
    actionType: types.SINGLE_CODE_UPDATE
  }),
  single_code_remove: requestsReducer({
    actionType: types.SINGLE_CODE_REMOVE
  }),
  single_code_view: requestsReducer({
    actionType: types.SINGLE_CODE_VIEW
  }),
  license_list: requestsReducer({
    actionType: types.LICENSE_LIST,
    resetOn: [
      types.LICENSE_BYE,
      types.LICENSE_USE
    ]
  }),
  license_bye: requestsReducer({
    actionType: types.LICENSE_BYE
  }),
  license_use: requestsReducer({
    actionType: types.LICENSE_USE
  }),
  license_activate: requestsReducer({
    actionType: types.LICENSE_ACTIVATE
  }),  
  monets_list: requestsReducer({
    actionType: types.MONETS_LIST,
    resetOn: [
      types.MONETS_ADD,
      types.MONETS_REMOVE,
      types.MONETS_UPDATE
    ]
  }),
  monets_add: requestsReducer({
    actionType: types.MONETS_ADD
  }),
  monets_update: requestsReducer({
    actionType: types.MONETS_UPDATE
  }),
  monets_view: requestsReducer({
    actionType: types.MONETS_VIEW,
    resetOn: [
      types.MONETS_ADD,
      types.MONETS_REMOVE,
      types.MONETS_UPDATE
    ]
  }),
  monets_options: requestsReducer({
    actionType: types.MONETS_OPTIONS,
    multiple: true,
    resetOn: [
      types.MONETS_ADD,
      types.MONETS_REMOVE,
      types.MONETS_UPDATE
    ]
  }),

  moneta_image_create: requestsReducer({
    actionType: types.MONETA_IMAGE_CREATE
  }),
  moneta_image_list: requestsReducer({
    actionType: types.MONETA_IMAGE_LIST,
    multiple: true
  }),
  monets_cryptobalances: requestsReducer({
    actionType: types.MONETS_CRUPTOBALANCES
  }),

  partner_balancepaydone: requestsReducer({
    actionType: types.PARTNER_BALANCEPAYDONE,
  }),
  news_list: requestsReducer({
    actionType: types.NEWS_LIST,
    resetOn: [
      types.NEWS_CREATE,
      types.NEWS_UPDATE,
      types.NEWS_REMOVE
    ]
  }),
  news_create: requestsReducer({
    actionType: types.NEWS_CREATE
  }),
  news_update: requestsReducer({
    actionType: types.NEWS_UPDATE
  }),
  news_remove: requestsReducer({
    actionType: types.NEWS_REMOVE
  }),
  news_view: requestsReducer({
    actionType: types.NEWS_VIEW,
    resetOn: [
      types.NEWS_CREATE,
      types.NEWS_UPDATE,
      types.NEWS_REMOVE
    ]
  }),
  news_newnotif: requestsReducer({
    actionType: types.NEWS_NEWNOTIF
  }),

  promocode_download: requestsReducer({
    actionType: types.PROMOCODE_DOWNLOAD
  }),
  promocode_list: requestsReducer({
    actionType: types.PROMOCODE_LIST,
    resetOn: [
      types.PROMOCODE_GENERATE
    ]
  }),
  promocode_generate: requestsReducer({
    actionType: types.PROMOCODE_GENERATE
  }),
  promocode_export: requestsReducer({
    actionType: types.PROMOCODE_EXPORT
  }),
    
  stat_list: requestsReducer({
    actionType: types.STAT_LIST
  }),
  stat_statreferals: requestsReducer({
    actionType: types.STAT_STATREFERALS
  }),

  draftscan_list: requestsReducer({
    actionType: types.DRAFTSCAN_LIST,
    resetOn: [
      types.DRAFTSCAN_ADD,
      types.DRAFTSCAN_REMOVE,
      types.DRAFTSCAN_UPDATE
    ]
  }),
  draftscan_add: requestsReducer({
    actionType: types.DRAFTSCAN_ADD
  }),
  draftscan_update: requestsReducer({
    actionType: types.DRAFTSCAN_UPDATE
  }),
  draftscan_view: requestsReducer({
    actionType: types.DRAFTSCAN_VIEW,
    resetOn: [
      types.DRAFTSCAN_ADD,
      types.DRAFTSCAN_REMOVE,
      types.DRAFTSCAN_UPDATE
    ]
  }),
  draftscan_options: requestsReducer({
    actionType: types.DRAFTSCAN_OPTIONS,
    multiple: true,
    resetOn: [
      types.DRAFTSCAN_ADD,
      types.DRAFTSCAN_REMOVE,
      types.DRAFTSCAN_UPDATE
    ]
  }),

  draftscan_stat: requestsReducer({
    actionType: types.DRAFTSCAN_STAT
  }),

  claim_list: requestsReducer({
    actionType: types.CLAIM_LIST,
    resetOn: [
      types.CLAIM_UPDATE_STATE,
      types.CLAIM_REMOVE
    ]
  }),
  claim_update_state: requestsReducer({
    actionType: types.CLAIM_UPDATE_STATE
  }),
  claim_remove: requestsReducer({
    actionType: types.CLAIM_REMOVE
  }),

});
