import React from 'react';
import { Form } from '../../firefly/component';
import TypeHead from '../TypeHead';

export default class PartnerSelect extends Form {
  
  componentDidMount() {
    const {
      siteId,
      fetch
    } = this.props;

    fetch({site_id: siteId});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      siteId,
      fetch
    } = this.props;

    if (prevProps.siteId !== siteId) {
      fetch({site_id: siteId})
    }
  }

  render() {
    const {
      partners,
      //name,
      value,
      onChange,
      loading
    } = this.props;

    //const nodes = partners.map((partner, i) => (
      //<option key={i} value={partner.id}>{partner.name}</option>
    //));

    return (
      (!loading ? <TypeHead
        onChange={onChange}
        objects={partners}
        value={value}
        placeholder="Выберите партнера"
      /> : <select className="b-input b-input--select"></select>)
    );
        
    /*return (
      <select
        className="b-input b-input--select"
        onChange={onChange}
        name={name}
        value={value}
        id={name}>
        <option value={null} />
        {nodes}
      </select>
    );*/
  }
}
