import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Pagination as Pager, Spinner } from '../../firefly/component';
import Table from '../Table';
import Error from '../Error';

export default class OrderTable extends Component {
  static propTypes = {
    notification: PropTypes.func.isRequired,
    filter: PropTypes.object,
    fetch: PropTypes.func.isRequired,
    pending: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number
    ]),
    objects: PropTypes.array,
    meta: PropTypes.object,
    error: PropTypes.any,
    className: PropTypes.string
  };

  static defaultProps = {
    pending: true,
    error: undefined,
    filter: {},
    objects: [],
    meta: null,
    className: ''
  };
  onPageChange = (page, page_size) => {
    this.fetch(page, page_size);
  };
  onPageSizeChange = page_size => {
    this.fetch(1, page_size);
  };
  fetch = (page = 1, page_size = 10) => {
    const {
      fetch,
      filter,
      siteId
    } = this.props;

    fetch({
      ...filter,
      page_size,
      page,
      show_all: 1,
      site_id: siteId
    });
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId
    } = this.props;

    if ((false === loading && null === objects) || prevProps.siteId !== siteId) {
      this.fetch();
    }
  }

  refresh() {
    this.fetch();
  }

  render() {
    const {
      className,
      pending,
      error,
      data
    } = this.props;

    if (pending) {
      return <Spinner />;
    }

    if (error) {
      return <Error />;
    }

    const columns = [
      {
        key: 'id',
        title: '№ Заказа'
      },
      {
        key: t => t.product && (
          <Link to={`/partner/${t.product.partner_id}`}>
            {t.product.partner.name}
          </Link>
        ),
        title: 'Партнер'
      },
      {
        key: t => t.product && (
          <Link to={`/product/${t.product.id}`}>
            {t.product.name}
          </Link>
        ),
        title: 'Подарок'
      },
      {
        key: 'created_at',
        title: 'Дата заказа'
      },
      {
        key: 'vydat_at',
        title: 'Дата получения'
      },

    ];

    let meta = [];
    let objects = [];
    if (data) {
      objects = data.objects;
      meta = data.meta;
    }

    return (
      <div>
        <Table
          className={className}
          columns={columns}
          data={objects} />
        {!pending && meta && <Pager
          onPageChange={this.onPageChange}
          pageCount={meta.page_count}
          page={meta.page}
          onPageSizeChange={this.onPageSizeChange}
          size={meta.page_size}
          total={meta.total} />}
      </div>
    );
  }
}
