import { bindConnect, getValidationErrors, isGranted } from '../../utils';
import NewsCreateForm from './NewsCreateForm';
import * as newsActions from '../../redux/actions/news';

export default bindConnect((state, props) => ({
  user: state.current_user.user,
  form: isGranted(state.current_user.user, 'partner') ? {
    ...props.form,
    site_id: props.siteId,
    partner_id: state.current_user.user.id
  } : {
    ...props.form,
    site_id: props.siteId
  },
  errors: getValidationErrors(state.news_create),
  loading: Boolean(state.news_create.pending)
}), {
  callback: newsActions.create
})(NewsCreateForm);
