import React from 'react';
import { Form } from '../../firefly/component';
import TypeHead from '../TypeHead';

export default class GenderFilter extends Form {

  render() {

    const { 
      onChange, 
      value,
      loading
    } = this.props;

    const objects = [{value: 1, label: 'Муж'}, {value: 2, label: 'Жен'}]

    return (
      (!loading ? <TypeHead
        onChange={onChange}
        objects={objects}
        value={value}
        placeholder="Пол"
      /> : <select className="b-input b-input--select"></select>)
    );
  }
}
