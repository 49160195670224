import { bindConnect } from '../../utils';
import CryptoWalletBalances from './CryptoWalletBalances';
import * as monetsActions from '../../redux/actions/monets';

export default bindConnect((state,props) => ({
  user: state.current_user.user,
  loading: Boolean(state.monets_cryptobalances.pending) || !state.monets_cryptobalances.data,
  ...(state.monets_cryptobalances.data || { meta: {}, objects: [] }),
  siteId: props.siteId
}), {
  fetch: monetsActions.cryptobalance,
})(CryptoWalletBalances);
