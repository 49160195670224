import * as types from '../actionTypes';

export function list(params) {
  return {
    type: types.MONETS_LIST,
    request: { url: '/partner/monets-list', params }
  };
}

export function remove(id) {
  return {
    type: types.MONETS_REMOVE,
    request: { url: `/partner/monets-remove/${id}`, method: 'delete'}
  };
}

export function view(id) {
  return {
    type: types.MONETS_VIEW,
    request: { url: `/partner/monets-view/${id}` }
  };
}

export function doMonetaUpdate(data) {
  let id = data instanceof FormData ? data.get('id') : data.id;
  if (id == null) {
    id = 0;
  }
  return {
    type: types.MONETS_UPDATE,
    request: { url: `/partner/moneta-update/${id}`, method: 'post', data }
  };
}

export function doFetchMonetaOptions(params) {
  return {
    type: types.MONETS_OPTIONS,
    request: { url: '/partner/moneta-options', params }
  };
}

export function cryptobalance(params) {
  return {
    type: types.MONETS_CRUPTOBALANCES,
    request: { url: '/wallet/system-crypto-balance', method: 'get', params }
  };
}
