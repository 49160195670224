import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';
import PartnerSelect from '../PartnerSelect';
import { isGranted } from '../../utils';
import MonetaTypeSelect from './MonetaTypeSelect';

export default class MonetaEditForm extends Form {

  prepare(form) {
    const formData = new FormData();

    Object.keys(form).forEach(key => {
      formData.set(key, form[key]);
    });

    return formData;
  }

  render() {
    const {
      user,
      errors,
      loading,
      id,
      siteId
    } = this.props;

    const {      
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>

        <Part
            component={MonetaTypeSelect}
            id="type"
            name="type"
            disabled={loading}
            label='Тип монеты'
            value={form.type || ''}
            errors={errors}
            onChange={this.change('type')} />

          <Part
            type="text"
            id="name"
            name="name"
            disabled={loading}
            label='Наименование'
            value={form.name || ''}
            errors={errors}
            onChange={this.change('name')} />

          <Part
            type="text"
            id="codename"
            name="codename"
            disabled={loading}
            label='Cокращенное наименование'
            value={form.codename || ''}
            errors={errors}
            onChange={this.change('codename')} />

          {id === undefined && (
            <div>
              <Part
                type="number"
                min="1000"
                required={true}
                id="emission"
                name="emission"
                disabled={loading}
                label='Число монет для эмиссии (изменение числа монет будет невозможно), стоимость 1000 монет - 1 Energy'
                value={form.emission || ''}
                errors={errors}
                onChange={this.change('emission')} />
              <div><p>Стоимость: {form.emission == null ? 0 : form.emission / 1000.0} Energy</p></div>
            </div>
          )}

          {(id !== undefined && !isGranted(user, 'admin')) && (
            <div><p>Число монет в эмиссии: {form.emission}</p></div>
          )}


          {(id !== undefined && isGranted(user, 'admin')) && (
              <Part
              type="number"
              min="1000"
              required={true}
              id="emission"
              name="emission"
              disabled={loading}
              label='Число монет для эмиссии'
              value={form.emission || ''}
              errors={errors}
              onChange={this.change('emission')} />
          )}

          <Part
            type="text"
            required={false}
            id="kurs_rub"
            name="kurs_rub"
            disabled={loading}
            label='Курс обмена (к Energy)'
            value={form.kurs_rub || ''}
            errors={errors}
            onChange={this.change('kurs_rub')} />

          <Part type="textarea"
            required={false}
            id="description"
            name="description"
            disabled={loading}
            label='Описание'
            value={form.description || ''}
            errors={errors}
            onChange={this.change('description')} />

          <Part
            type="text"
            required={false}
            id="url"
            name="url"
            disabled={loading}
            label='Url проекта'
            value={form.url || ''}
            errors={errors}
            onChange={this.change('url')} />

          {form.type > 0 && (
            <Part
              type="text"
              required={false}
              id="url_zeroscan"
              name="url_zeroscan"
              disabled={loading}
              label='Url Blockchain Explorer'
              value={form.url_zeroscan || ''}
              errors={errors}
              onChange={this.change('url_zeroscan')} />
          )}

          <Part
            type="file"
            id="logo"
            name="logo"
            required={false}
            disabled={loading}
            label='Логотип (*.png, *.jpg, до 8МБ)'
            accept=".png, .jpg, .jpeg"
            errors={errors}
            onChange={this.change('logo')} />

          <Part
            type="file"
            id="link3dmodel"
            name="link3dmodel"
            required={false}
            disabled={loading}
            label='3D модель (fbx)'
            accept=".fbx"
            errors={errors}
            onChange={this.change('link3dmodel')} />            

          {isGranted(user, 'admin') && id === undefined && (
            <Part
              component={PartnerSelect}
              id="partner_id"
              name="partner_id"
              disabled={loading}
              label='Партнер'
              value={form.partner_id || ''}
              errors={errors}
              onChange={this.change('partner_id')}
              siteId={siteId}
            />
          )}

          <FormRow>
            <button
              type="submit"
              disabled={loading}
              className="b-button">
              Продолжить
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
