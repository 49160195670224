import { bindConnect } from '../../utils';
import ProductSelect from './ProductSelect';
import * as productActions from '../../redux/actions/product';

export default bindConnect(state => ({
  objects: state.product_options.data || [],
  loading: Boolean(state.product_options.pending) || null === state.product_options.data
}), {
  fetch: productActions.options
})(ProductSelect);
