import React, { Component } from 'react';
import { H1 } from '../../../firefly/component';
import { Redirect } from 'react-router-dom';
import RestoreConfirmForm from '../../../component/RestoreConfirmForm';
import Page from '../../../component/Page';
import Transition from '../../../component/Transition';

export default class RestoreConfirm extends Component {
  state = {
    success: false
  };

  onSuccess = () => {
    this.setState({
      success: true
    });
  };

  render() {
    const {
      success
    } = this.state;

    if (success) {
      return <Redirect to='/' />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/restore', text: 'Восстановление пароля' },
      { text: 'Подтверждение восстановления пароля' }
    ];

    return (
      <Page title='Подтверждение восстановления пароля' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Восстановление пароля</H1>
          <RestoreConfirmForm onSuccess={this.onSuccess} />
        </Transition>
      </Page>
    );
  }
}
