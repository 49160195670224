import { bindConnect } from '../../utils';
import SingleCodeUpdate from './SingleCodeUpdate';
import * as singleCodeActions from '../../redux/actions/singleCode';

export default bindConnect(state => ({
  loading: Boolean(state.single_code_view.pending) || !state.single_code_view.data,
  code: state.single_code_view.data
}), {
  fetch: singleCodeActions.view
})(SingleCodeUpdate);
