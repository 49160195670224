import { bindConnect } from '../../utils';
import CityTable from './CityTable';
import * as cityActions from '../../redux/actions/city';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect(state => ({
  loading: Boolean(state.city_list.pending) || null === state.city_list.data,
  objects: state.city_list.data
}), {
  fetch: cityActions.list,
  remove: cityActions.remove,
  notification: notificationActions.add
})(CityTable);
