import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import LicenseByeForm from '../../component/LicenseByeForm';

export default class LicenseBye extends Component {
  static defaultProps = {
    loading: true,
    objects: null,
    filter: {},
    meta: {},
    className: ''
  };

  state = {
    success: false,
    id: undefined
  };

  onSuccess = ({ response }) => {
    this.setState({
      id: response.data.id,
      success: true
    });
  };

  fetch = (id = 0) => {
    const {
      fetch,
      filter
    } = this.props;

    fetch({
      ...filter,
      id
    });
  };

  componentDidMount() {
    //this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId
    } = this.props;
    console.log(objects);

    if ((false === loading && null === objects) || prevProps.siteId !== siteId) {
      fetch();
    }
  }

  render() {
    const {
      success,
    } = this.state;

    if (success) {
      return <Redirect to={`/licenses`} />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/licenses', text: 'Рефералы' },
      { text: 'Создать реферальную ссылку' }
    ];

    return (
      <Page title='Создать реферальную ссылку' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Создать реферальную ссылку</H1>
          <LicenseByeForm onSuccess={this.onSuccess} />
        </Transition>
      </Page>
    );
  }
}
