export const BACKSPACE = 8;
export const TAB = 9;
export const ENTER = 13;
export const SHIFT = 16;
export const ESCAPE = 27;
export const SPACE = 32;
export const ARROW_LEFT = 37;
export const ARROW_UP = 38;
export const ARROW_RIGHT = 39;
export const ARROW_DOWN = 40;
export const DELETE = 46;
