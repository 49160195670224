import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

export default class MonetaImgUploader extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    upload: PropTypes.func.isRequired,
    callback: PropTypes.func.isRequired
  };

  onDrop = files => {
    const {
      id,
      callback,
      upload
    } = this.props;

    const formData = new FormData();
    formData.append('moneta_id', id);

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }

    upload(formData).then(() => {
      callback();
    });
  };

  render() {
    return (
      <Dropzone
        style={null}
        multiple
        accept="image/*, video/mp4"
        onDrop={this.onDrop}
        className="dropzone">
        <div className="dropdown__text">
          Перетащите файлы сюда или нажмите для загрузки
        </div>
      </Dropzone>
    );
  }
}
