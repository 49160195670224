import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { H1 } from '../../firefly/component';
import SettingsForm from '../../component/Settings/Form';
import Page from '../../component/Page';
import { Spinner } from '../../firefly/component';
import Transition from '../../component/Transition';

export default class Settings extends Component {
  static propTypes = {
    settings: PropTypes.object,
    loading: PropTypes.bool,
    notification: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired
  };

  static defaultProps = {
    settings: null,
    loading: true
  };

  fetch = () => {
    const {
      fetch,
      siteId
    } = this.props;

    fetch({
      site_id: siteId
    });
  };  

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      siteId
    } = this.props;

    if (prevProps.siteId !== siteId) {
      this.fetch();
    }
  }

  onSuccess = () => {
    const {
      notification
    } = this.props;

    notification({
      title: 'Выполнено',
      text: 'Настройки сохранены'
    });
  };

  render() {
    const {
      loading,
      settings,
      siteId
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Настройки' }
    ];

    return (
      <Page title='Настройки' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Настройки</H1>
          {loading ? <Spinner /> : <SettingsForm
            form={settings}
            siteId={siteId}
            onSuccess={this.onSuccess} />}
        </Transition>
      </Page>
    );
  }
}
