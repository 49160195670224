import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';
import PartnerSelect from '../PartnerSelect';
import RoleSelect from './RoleSelect';
import { isGranted } from '../../utils';

export default class UserCreateForm extends Form {

  prepare(form) {
    const formData = new FormData();

    Object.keys(form).forEach(key => {
      const {
        siteId
      } = this.props;

      formData.set(key, form[key]);
      formData.set('site_id', siteId);
    });

    return formData;
  }

  render() {
    const {
      user,
      errors,
      loading,
      siteId
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part
            type="emailPartner"
            id="emailPartner"
            name="emailPartner"
            disabled={loading}
            label='Логин (email)'
            value={form.emailPartner || ''}
            errors={errors}
            onChange={this.change('emailPartner')} />

          <Part
            type="password"
            id="password"
            name="password"
            disabled={loading}
            label='Пароль'
            value={form.password || ''}
            errors={errors}
            onChange={this.change('password')} />

          <Part
            type="password"
            id="password_confirm"
            name="password_confirm"
            disabled={loading}
            label='Пароль повторно'
            value={form.password_confirm || ''}
            errors={errors}
            onChange={this.change('password_confirm')} />

          <Part
            type="text"
            id="fam"
            name="fam"
            disabled={loading}
            label='Фамилия'
            value={form.fam || ''}
            errors={errors}
            onChange={this.change('fam')} />

          <Part
            type="text"
            id="name"
            name="name"
            disabled={loading}
            label='Имя'
            value={form.name || ''}
            errors={errors}
            onChange={this.change('name')} />

          <Part
            required={false}
            type="text"
            id="otch"
            name="otch"
            disabled={loading}
            label='Отчество'
            value={form.otch || ''}
            errors={errors}
            onChange={this.change('otch')} />

          <Part
              component={RoleSelect}
              id="partner_role"
              name="partner_role"
              disabled={loading}
              label='Роль'
              value={form.partner_role || ''}
              errors={errors}
              onChange={this.change('partner_role')} />
 

          {isGranted(user, 'admin') && (
            <Part
              component={PartnerSelect}
              id="partner_id"
              name="partner_id"
              disabled={loading}
              label='Партнер'
              value={form.partner_id || ''}
              errors={errors}
              onChange={this.change('partner_id')}
              siteId={siteId}
            />
          )}

          <FormRow>
            <button
              type="submit"
              disabled={loading}
              className="b-button">
              Сохранить
            </button>
          </FormRow>
        </form> 
        
      </SpinnerOverlay>
    );
  }
}
