import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import { Link } from 'react-router-dom';
import { bindConnect } from '../../utils';
import { Popover } from '../../firefly/component';
import SiteSelect from 'component/SiteSelect/SiteSelect';

class Header extends Component {
  static propTypes = {
    user: PropTypes.object,
    logout: PropTypes.func.isRequired
  };
  static defaultProps = {
    user: null
  };
  profile = undefined;
  refHandlers = {
    profile: profile => this.profile = profile
  };

  closePopover = () => {
    if (this.profile) {
      this.profile.close();
    }
  };

  onLogout = e => {
    e.preventDefault();

    this.closePopover();

    const {
      logout
    } = this.props;

    if (window.confirm('Вы действительно хотите выйти?')) {
      logout();
    }
  };

  onSiteChange = (event) => {
    //console.log(event.target.value);
    const {
      setSiteId,
    } = this.props;
    setSiteId(event.target.value);
  }

  onHideMenu = (event) => {
    const {
      hideMenu,
    } = this.props;
    hideMenu();
  }

  render() {
    const {
      user,
      siteId      
    } = this.props;

    return (
      <>
        <div className="header__menubtn">
          <button className="menu__item" onClick={this.onHideMenu}>
            <Icons.Menu />
          </button>
        </div>
        {user && (
          <div className="header">
            <div className="header__select">
              <SiteSelect onChange={this.onSiteChange} value={siteId}/>
            </div>
            <Popover ref={this.refHandlers.profile} modifiers="menu">
              <button className='menu__link'>
                Профиль
              </button>
              <div>
                <Link onClick={this.closePopover} className="menu__item" to="/change_password">
                  Изменить пароль
                </Link>
                <button className="menu__item" onClick={this.onLogout}>
                  Выход
                </button>
              </div>
            </Popover>
          </div>
        )}
      </>
    );
  }
}

export default bindConnect(state => ({
  user: state.current_user.user
}))(Header);
