import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';

export default class LoginForm extends Form {
  static propTypes = {
    errors: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    loading: PropTypes.bool.isRequired,
    callback: PropTypes.func.isRequired
  };

  render() {
    const {
      errors,
      loading
    } = this.props;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit} className="form">
          <Part type="text"
                name="login"
                errors={errors}
                disabled={loading}
                autoFocus={true}
                autoCapitalize="off"
                autoCorrect="off"
                label='Электронная почта'
                value={this.state.form.login || ''}
                onChange={this.change('login')} />
          <Part type="password"
                errors={errors}
                name="password"
                disabled={loading}
                autoComplete="new-password"
                label='Пароль'
                className="b-input b-input_white b-input__icon b-input__icon_password"
                value={this.state.form.password || ''}
                onChange={this.change('password')} />
          <FormRow className="b-form__pane">
            <button type="submit"
                    disabled={loading}
                    className="b-button">
              Вход
            </button>
            <div className="menu__row">
              <Link className="menu__link"
                    to="/registration">
                Регистрация
              </Link>
              <Link className="menu__link"
                    to="/restore">
                Восстановить пароль
              </Link>
            </div>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
