import { bindConnect } from '../../utils';
import PromocodeTable from './PromocodeTable';
import { doFetchPromocodeList } from '../../redux/actions/promocode';

export default bindConnect(state => ({
  loading: Boolean(state.promocode_list.pending) || !state.promocode_list.data,
  ...(state.promocode_list.data || { meta: {}, objects: [] })
}), {
  fetch: doFetchPromocodeList
})(PromocodeTable);
