import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../firefly/component';
import MonetaSelect from './MonetaSelect';

export default class SingleCodeForm extends Form {

  prepare(form) {
    const {
      siteId
    } = this.props;

    form['site_id'] = siteId;
    return form;
  }

  render() {
    const {
      errors,
      loading,
      siteId
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part
            type="text"
            id="code"
            name="code"
            disabled={loading}
            label='Код'
            value={form.code || ''}
            errors={errors}
            onChange={this.change('code')} />

          <Part
            type="number"
            id="amount"
            name="amount"
            min="1"
            disabled={loading}
            label='Сумма'
            value={form.amount || ''}
            errors={errors}
            onChange={this.change('amount')} />

          <Part
              component={MonetaSelect}
              id="moneta_id"
              name="moneta_id"
              disabled={loading}
              label='Монета'
              value={form.moneta_id || ''}
              errors={errors}
              onChange={this.change('moneta_id')}
              siteId={siteId}
            />

          <Part
            required={false}
            type="textarea"
            id="comment"
            name="comment"
            disabled={loading}
            label='Комментарии'
            value={form.comment || ''}
            errors={errors}
            onChange={this.change('comment')}
          />

          <FormRow>
            <button
              type="submit"
              disabled={loading}
              className="b-button">
              Сохранить
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
