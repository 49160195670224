import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../firefly/component';
import PartnerSelect from './PartnerSelect';
import MonetaSelect from './MonetaSelect';
import CitySelect from './CitySelect';
import CategSelect from './CategSelect';
import { DateInput } from '../firefly/component/DateTime';
import { isGranted } from '../utils';

export default class ProductForm extends Form {
  
  componentDidMount() {
    const { form } = this.props;
    delete form.user;
    delete form.partner;
    delete form.created_at;
    delete form.updated_at;

    this.setState({ form });
  }

  parseDate(date) {
    console.log(date);
    let d = new Date(date);
    return d;
  }

  formatDate(date, locale) {
    let d = new Date(date);
    d = d.toISOString();
    return d.substr(8,2) + "." + d.substr(5,2) + "." + d.substr(0,4);
  }

  parseDateStr(date) {
    if (date) {
      let d = new Date(date);
      return d;
    }
  }

  render() {
    const {
      errors,
      loading,
      user,
      siteId
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>
          <Part type="text"
                id="name"
                name="name"
                disabled={loading}
                label='Название'
                value={form.name || ''}
                errors={errors}
                onChange={this.change('name')} />
          <Part type="number"
                min="1"
                id="price"
                name="price"
                disabled={loading}
                label='Стоимость'
                value={form.price || ''}
                errors={errors}
                onChange={this.change('price')} />                 
          <Part
              component={MonetaSelect}
              id="moneta_id"
              name="moneta_id"
              disabled={loading}
              label='Монета'
              value={form.moneta_id || ''}
              errors={errors}
              onChange={this.change('moneta_id', 'reactselect')}
              siteId={siteId}
            />       

          <Part type="number"
                min="0"
                id="quantity"
                name="quantity"
                disabled={loading}
                label='Количество'
                value={form.quantity || ''}
                errors={errors}
                onChange={this.change('quantity')} />

          <Part type="textarea"
                id="description"
                name="description"
                disabled={loading}
                label='Описание'
                value={form.description || ''}
                errors={errors}
                onChange={this.change('description')} />

          <Part
            component={
              () => (
                <DateInput 
                  locale="ru-RU" 
                  value={this.parseDateStr(form.date_end)} 
                  parseDate={this.parseDate} 
                  formatDate={this.formatDate} 
                  onChange={(e) => {
                    const { form } = this.state;
                    form.date_end = this.parseDateStr(e);
                    this.setState({
                      form: {
                        ...form,
                      }
                    });
                    console.log(form);
                  }}
                />
            )}
            required={false}
            id="date_end"
            name="date_end"
            disabled={loading}
            label='Срок действия'
            value={form.date_end || ''}
            errors={errors}
            onChange={this.change('date_end')} />

          <Part type="checkbox"
            required={false}
            id="is_enabled"
            name="is_enabled"
            disabled={loading}
            label='Активен'
            value={form.is_enabled || ''}
            errors={errors}
            onChange={this.change('is_enabled')} />

          <Part
            component={CategSelect}
            required={false}
            id="categ"
            name="categ"
            disabled={loading}
            label='Категория'
            value={form.categ}
            errors={errors}
            onChange={this.change('categ')} />

          <Part
            component={CitySelect}
            required={false}
            id="city_id"
            name="city_id"
            disabled={loading}
            label='Город'
            value={form.city_id || ''}
            errors={errors}
            onChange={this.change('city_id', 'reactselect')} />

          {isGranted(user, 'admin') && (
            <Part
              component={PartnerSelect}
              id="partner_id"
              name="partner_id"
              disabled={loading}
              label='Партнер'
              value={form.partner_id || ''}
              errors={errors}
              onChange={this.change('partner_id', 'reactselect')}
              siteId={siteId}
            />
          )}

          <FormRow>
            <button type="submit"
                    disabled={loading}
                    className="b-button">
              Сохранить
            </button>
          </FormRow>
        </form>
      </SpinnerOverlay>
    );
  }
}
