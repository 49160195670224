import { bindConnect } from '../../utils';
import ProductView from './ProductView';
import * as productActions from '../../redux/actions/product';
import { doFetchProductImages } from '../../redux/actions/product_image';

export default bindConnect(state => ({
  loading: Boolean(state.product_view.pending) || !state.product_view.data,
  product: state.product_view.data
}), {
  fetch: productActions.view,
  fetchImages: doFetchProductImages
})(ProductView);
