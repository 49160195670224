import React from 'react';
//import PropTypes from 'prop-types';
import { Form, H3 } from '../../firefly/component';

export default class ProductKassaVydat extends Form {

  prepare(form) {

    const {
      order
    } = this.props;
    //console.dir(order);

    const formData = new FormData();
    formData.set('id', order.id);

    return formData;
  }

  render() {
    const {
      order
    } = this.props;
    console.dir(order);
    const product = order.product;

    return (
      <div>        
        <H3>{product.name}</H3>
        <div>
          <div>Стоимость: {product.price}</div>
        </div>
        <div>Дата покупки: {order.created_at}</div>
        <div className="divider" />
        <div>{product.description}</div>
        <form onSubmit={this.submit}>
          <button type="submit" className="b-button">
            Выдать подарок
          </button>
        </form>
      </div>
    );
  }
}
