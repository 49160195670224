import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import ClaimTable from '../../component/ClaimTable';
import { isGranted } from '../../utils';

export default class ClaimList extends Component {
  static propTypes = {
    user: PropTypes.object
  };

  static defaultProps = {
    user: null
  };

  render() {
    const {
      user,
      siteId
    } = this.props;

    return (
      <Page title='Заявка о поломке'>
        {isGranted(user, 'admin') ? <Transition>
          <H1>Заявка о поломке</H1>
          <ClaimTable siteId={siteId} />
        </Transition> : <div></div>}
      </Page>
    );
  }
}
