import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import ProductKassaForm from '../../component/ProductKassaForm';
import ProductKassaVydat from '../../component/ProductKassaVydat';

export default class ProductKassa extends Component {
  state = {
    success: false,
    successvydat: false,
    id: undefined,
    order: undefined
  };

  onSuccess = ({ response }) => {
    this.setState({
      order: response.data,
      id: response.data.product_id,
      success: true
    });
  };

  onSuccessVydat = ({ response }) => {
    this.setState({
      successvydat: true
    });

    const {
      notification
    } = this.props;
    notification({ title: 'Подарок выдан' });
  };

  render() {
    const {
      success,
      successvydat,
      order,
      id
    } = this.state;

    const {
      siteId
    } = this.props;

    if (successvydat) {
      return <Redirect to={`/product/${id}`} />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/product', text: 'Подарки' },
      { icon: 'document', text: 'Выдача подарка' }
    ];

    return (
      <Page title='Выдача подарка' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Выдача подарка</H1> {
            success ? (
              <ProductKassaVydat 
                onSuccess={this.onSuccessVydat}
                order={order}
                siteId={siteId} />
            ) : (
              <ProductKassaForm
                onSuccess={this.onSuccess}
                siteId={siteId} />
            )
          }
        </Transition>
      </Page>
    );
  }
}
