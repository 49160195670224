import React from 'react';
import { Form, FormRow, Part, SpinnerOverlay } from '../../firefly/component';
import PartnerSelect from '../PartnerSelect';
import { isGranted } from '../../utils';
import { DateInput } from '../../firefly/component/DateTime';

export default class StatReferalsFilter extends Form {

  parseDate(date) {
    console.log(date);
    let d = new Date(date);
    return d;
  }

  formatDate(date, locale) {
    let d = new Date(date);
    d = d.toISOString();
    return d.substr(8,2) + "." + d.substr(5,2) + "." + d.substr(0,4);
  }

  parseDateStr(date) {
    if (date) {
      let d = new Date(date);
      return d;
    }
  }

  render() {
    const {
      user,
      errors,
      loading
    } = this.props;

    const {
      form
    } = this.state;

    return (
      <SpinnerOverlay show={loading}>
        <form onSubmit={this.submit}>

          <Part
            component={
              () => (
                <DateInput 
                  locale="ru-RU" 
                  value={this.parseDateStr(form.start_at)} 
                  parseDate={this.parseDate} 
                  formatDate={this.formatDate} 
                  onChange={(e) => {
                    const { form } = this.state;
                    form.start_at = this.parseDateStr(e);
                    this.setState({
                      form: {
                        ...form,
                      }
                    });
                    console.log(form);
                  }}
                />
            )}
            id="start_at"
            name="start_at"
            disabled={loading}
            label='Дата начала'
            value={form.start_at || ''}
            errors={errors}
            onChange={this.change('start_at')} />
          
          <Part
            component={
              () => (
                <DateInput 
                  locale="ru-RU" 
                  value={this.parseDateStr(form.end_at)} 
                  parseDate={this.parseDate} 
                  formatDate={this.formatDate} 
                  onChange={(e) => {
                    const { form } = this.state;
                    form.end_at = this.parseDateStr(e);
                    this.setState({
                      form: {
                        ...form,
                      }
                    });
                    console.log(form);
                  }}
                />
            )}
            id="end_at"
            name="end_at"
            disabled={loading}
            label='Дата окончания'
            value={form.end_at || ''}
            errors={errors}
            onChange={this.change('end_at')} />

          {isGranted(user, 'admin') && (
            <Part
              component={PartnerSelect}
              id="partner_id"
              name="partner_id"
              disabled={loading}
              label='Партнер'
              value={form.partner_id || ''}
              errors={errors}
              onChange={this.change('partner_id')} />
          )}

          <FormRow>
            <button
              type="submit"
              disabled={loading}
              className="b-button">
              Получить
            </button>
          </FormRow>
        </form>
        
      </SpinnerOverlay>
    );
  }
}
