import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { H1, SmartDetailTable } from '../../firefly/component';
import * as Icons from 'react-feather';

export default class PartnerDetail extends Component {
  static propTypes = {
    partner: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string
    }).isRequired
  };

  render() {
    const {
      partner
    } = this.props;

    const mapping = [
      {
        title: 'Логотип',
        value: x => x.logo ? (
          <img src={x.logo_url} alt={x.name} className='partner__logo' />
        ) : (
          <Icons.Minus className='icon' />
        )
      },
      {
        title: 'Руководитель',
        value: x => x.user && (
          <Link to={`/user/${x.user_id}`}>
            {x.user.email}
          </Link>
        )
      },
      {
        title: 'Описание',
        value: 'description'
      },
      {
        title: 'Подарки',
        value: x => (
          <Link to={`/product?partner_id=${x.id}`}>Перейти</Link>
        )
      },
      {
        title: 'Конкурсы',
        value: x => (
          <Link to={`/contest?partner_id=${x.id}`}>Перейти</Link>
        )
      },
      {
        title: 'Опросы',
        value: x => (
          <Link to={`/poll?partner_id=${x.id}`}>Перейти</Link>
        )
      }
    ];

    return (
      <div>
        <div className="detail-view__panel">
          <H1>{partner.name}</H1>
          <div className='detail-view__buttons'>
            <Link
              className="b-button b-button--outline"
              to={`/partner/update/${partner.id}`}>
              Изменить
            </Link>
          </div>
        </div>
        <SmartDetailTable
          object={partner}
          mapping={mapping} />
      </div>
    );
  }
}
