import { bindConnect, getValidationErrors } from '../../../utils';
import SettingsForm from './SettingsForm';
import * as settingsActions from '../../../redux/actions/settings';

export default bindConnect(state => ({
  errors: getValidationErrors(state.settings_save),
  loading: Boolean(state.settings_save.pending)
}), {
  callback: settingsActions.save
})(SettingsForm);
