import { bindConnect } from '../../utils';
import NewsUpdate from './NewsUpdate';
import * as newsActions from '../../redux/actions/news';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect(state => ({
  loading: Boolean(state.news_view.pending) || !state.news_view.data
  || Boolean(state.news_view.pending) || !state.news_view.data,
  newsdata: state.news_view.data,
}), {
  notification: notificationActions.add,
  fetch: newsActions.view
})(NewsUpdate);
