import { bindConnect } from '../../utils';
import UserActivities from './UserActivities';
import * as userActions from '../../redux/actions/user';

export default bindConnect((state,props) => ({
  user: state.current_user.user,
  loading: Boolean(state.user_activities.pending) || !state.user_activities.data,
  ...(state.user_activities.data || { meta: {}, objects: [] }),
  siteId: props.siteId
}), {
  fetch: userActions.activities
})(UserActivities);
