import { bindConnect } from '../../utils';
import PromocodeQr from './PromocodeQr';
import { doPromocodeDownload } from '../../redux/actions/promocode';

export default bindConnect(state => ({
  loading: Boolean(state.promocode_download.pending) || !state.promocode_download.data,
  image: state.promocode_download.data
}), {
  fetch: doPromocodeDownload
})(PromocodeQr);
