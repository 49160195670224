import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import PartnerDetail from '../../component/PartnerDetail';
import { Spinner } from '../../firefly/component';
import PermissionContainer from '../../component/PermissionContainer';

export default class PartnerView extends Component {
  static propTypes = {
    partner: PropTypes.object,
    loading: PropTypes.bool,
    fetch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  static defaultProps = {
    user: null,
    loading: true,
    partner: null
  };

  UNSAFE_componentWillMount() {
    const {
      match,
      fetch
    } = this.props;

    fetch(match.params.id);
  }

  UNSAFE_componentWillReceiveProps({ match, fetch }) {
    if (match.params.id !== this.props.match.params.id) {
      fetch(match.params.id);
    }
  }

  render() {
    const {
      loading,
      partner
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/partner', text: 'Партнеры' },
      { text: 'Детальный просмотр партнера' }
    ];

    return (
      <Page title='Детальный просмотр партнера' breadcrumbs={breadcrumbs}>
        <PermissionContainer>
          {loading ? <Spinner /> : (
            <Transition>
              <PartnerDetail partner={partner} />
            </Transition>
          )}
        </PermissionContainer>
      </Page>
    );
  }
}
