import { withRouter } from 'react-router-dom';
import { didRestoreCheck, didRestoreConfirm, didRestoreResend } from '../../redux/actions/auth';
import { bindConnect, isValidationError } from '../../utils';
import RestoreConfirmForm from './RestoreConfirmForm';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect(state => {
  let errors = {};
  if (isValidationError(state.restore_confirm)) {
    errors = {
      ...errors,
      ...state.restore_confirm.error.response.data.errors
    };
  }

  if (isValidationError(state.restore_check)) {
    errors = {
      ...errors,
      ...state.restore_check.error.response.data.errors
    };
  }

  return {
    errors,
    loading: Boolean(state.restore_confirm.pending) || Boolean(state.restore_resend.pending)
  };
}, {
  confirm: didRestoreConfirm,
  check: didRestoreCheck,
  resend: didRestoreResend,
  notification: notificationActions.add
})(withRouter(RestoreConfirmForm));
