import * as types from '../actionTypes';

export function list(params) {
  return {
    type: types.CLAIM_LIST,
    request: { url: `/claim/list`, params }
  };
}

export function updateState(id) {
  return {
    type: types.CLAIM_UPDATE_STATE,
    request: { url: `/claim/update-state/${id}`, method: 'delete' }
  };
}

export function remove(id) {
  return {
    type: types.CLAIM_REMOVE,
    request: { url: `/claim/remove/${id}`, method: 'delete' }
  };
}
