import { bindConnect } from '../../utils';
import ProductUpdate from './ProductUpdate';
import * as productActions from '../../redux/actions/product';

export default bindConnect(state => ({
  loading: Boolean(state.product_view.pending) || !state.product_view.data,
  product: state.product_view.data
}), {
  fetch: productActions.view
})(ProductUpdate);
