import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import { Pagination as Pager, Spinner } from '../../firefly/component';
import Table from '../Table';

export default class ClaimTable extends Component {
  static propTypes = {
    notification: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    filter: PropTypes.object,
    remove: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    objects: PropTypes.array,
    meta: PropTypes.object,
    className: PropTypes.string
  };

  static defaultProps = {
    loading: true,
    objects: [],
    filter: {},
    meta: {},
    className: '',
    siteId: 0
  };
  onPageChange = (page, page_size) => {
    this.fetch(page, page_size);
  };
  onPageSizeChange = page_size => {
    this.fetch(1, page_size);
  };
  fetch = (page = 1, page_size = 10) => {
    const {
      fetch,
      filter,
      siteId
    } = this.props;

    fetch({
      ...filter,
      page_size,
      page,
      site_id: siteId
    });
  };
  onRemove = id => e => {
    e.preventDefault();

    const {
      remove,
      notification
    } = this.props;

    if (window.confirm('Удалить?')) {
      remove(id).then(() => {
        notification({ title: 'Удалено' });
        this.fetch();
      });
    }
  };

  onClose = id => e => {
    e.preventDefault();

    const {
      updateState,
      notification
    } = this.props;

    if (window.confirm('Изменить статус на обработана?')) {
      updateState(id).then(() => {
        notification({ title: 'Заявка обработана' });
        this.fetch();
      });
    }    
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId
    } = this.props;

    if ((false === loading && null === objects) || prevProps.siteId !== siteId) {
      this.fetch();
    }
  }

  render() {
    const {
      className,
      loading,
      meta,
      objects
    } = this.props;

    if (loading) {
      return <Spinner />;
    }

    const columns = [
      {
        key: t => t.id,
        title: 'Номер'
      },
      {
        key: 'created_at',
        title: 'Дата'
      },      
      /*{
        key: t => t.address,
        title: 'Адрес'
      },*/
      {
        key: t => (
          <div>
            {t.locationX}, {t.locationY}
          </div>
        ),
        title: 'Гео'
      },
      {
        key: t => (
          <a className="link" target='_blank' rel="noreferrer" href={t.photoLink}>{t.photo}</a>
        ),
        title: 'Фото'
      },
      {
        key: t => (
          <Fragment>
            {t.state === 0 ? (<button onClick={this.onClose(t.id)} className="link">Новая</button>) : ''}
            {t.state === 1 ? 'Обработана' : ''}
          </Fragment>
        ),
        title: 'Статус'
      },
      {
        key: x => (
          <Fragment>
            <button onClick={this.onRemove(x.id)} className="link">
              <Icons.Trash2 className="icon" />
            </button>
          </Fragment>
        ),
        title: '',
        tdStyle: {
          align: 'right',
          width: 100,
          textAlign: 'center'
        }
      }
    ];

    return (
      <div>
        <Table
          className={className}
          columns={columns}
          data={objects} />
        {!loading && <Pager
          onPageChange={this.onPageChange}
          pageCount={meta.page_count}
          page={meta.page}
          onPageSizeChange={this.onPageSizeChange}
          size={meta.page_size}
          total={meta.total} />}
      </div>
    );
  }
}
