import * as types from '../actionTypes';

export function list(params) {
  return {
    type: types.CITY_LIST,
    request: { url: '/city', params }
  };
}

export function options(params) {
  return {
    type: types.CITY_OPTIONS,
    request: { url: '/city/options', params }
  };
}

export function view(id) {
  return {
    type: types.CITY_VIEW,
    request: { url: `/city/${id}` }
  };
}

export function create(data) {
  return {
    type: types.CITY_CREATE,
    request: { url: '/city', method: 'post', data }
  };
}

export function update(data) {
  const { id } = data;

  return {
    type: types.CITY_UPDATE,
    request: { url: `/city/${id}`, method: 'post', data }
  };
}

export function remove(id) {
  return {
    type: types.CITY_REMOVE,
    request: { url: `/city/${id}`, method: 'delete' }
  };
}
