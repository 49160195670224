import { bindConnect } from '../../utils';
import PartnerSelect from './PartnerSelect';
import { doFetchPartnerOptions } from '../../redux/actions/partner';

export default bindConnect(state => ({
  partners: state.partner_options.data,
  loading: Boolean(state.partner_options.pending)
}), {
  fetch: doFetchPartnerOptions
})(PartnerSelect);
