import React, { Component } from 'react';
import { H1 } from '../../../firefly/component';
import ChangePasswordForm from '../../../component/ChangePasswordForm';
import Page from '../../../component/Page';
import Transition from '../../../component/Transition';

export default class ChangePassword extends Component {
  render() {
    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Изменить пароль' }
    ];

    return (
      <Page title='Изменить пароль' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Изменить пароль</H1>
          <ChangePasswordForm />
        </Transition>
      </Page>
    );
  }
}
