import React, { Component } from 'react';
import { H1 } from '../../firefly/component';
import { Link } from 'react-router-dom';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import LicenseTable from '../../component/LicenseTable';
import { isGranted } from "../../utils";

export default class LicensesList extends Component {

  static defaultProps = {
    user: null,
    loading: true,
    partner: null
  };

  componentDidMount() {
    const {
      fetch
    } = this.props;

    fetch(0);
  }

  render() {
    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Рефералы' }
    ];

    const {
      user
    } = this.props;

    const filter = isGranted(user, 'admin') ? {} : {
      partner_id: user.id
    };
    
    const {
      //loading,
      partner
    } = this.props;  

    return (
      <Page title='Рефералы' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Рефералы</H1>
          {partner !== null && partner.is_account_activation === "1" ? 
            (<div>
              <div className="toolbar">
                <Link to='/licenses/bye' className="b-button">
                  Создать реферальную ссылку
                </Link>
                &nbsp;
                <Link to='/statreferals' className="b-button">
                  Статистика
                </Link>
              </div>
              <LicenseTable filter={filter} />
            </div>) : 
            (<div>Недоступно в демо режиме</div>)
          }
        </Transition>
      </Page>
    );
  }
}
