import { bindConnect } from '../../utils';
import ContestView from './ContestView';
import * as contestActions from '../../redux/actions/contest';

export default bindConnect(state => ({
  loading: Boolean(state.contest_view.pending) || !state.contest_view.data,
  contest: state.contest_view.data
}), {
  fetch: contestActions.view
})(ContestView);
