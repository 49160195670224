import { bindConnect, getValidationErrors, isGranted } from '../../utils';
import LicenseByeForm from './LicenseByeForm';
import * as licenseActions from '../../redux/actions/license';

export default bindConnect((state, props) => ({
  user: state.current_user.user,
  form: isGranted(state.current_user.user, 'partner') ? {
    ...props.form,
    partner_id: state.current_user.user.id
  } : {
    ...props.form
  },
  errors: getValidationErrors(state.license_bye),
  loading: Boolean(state.license_bye.pending)
}), {
  callback: licenseActions.doLicenseBye
})(LicenseByeForm);
