import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import NotifCreateForm from '../../component/NotifCreateForm';

export default class NewNotif extends Component {

  state = {
    success: false
  };

  onSuccess = () => {
    this.props.notification({ title: 'Выполнено' });

    this.setState({
      success: true
    });
  };

  render() {

    const { success } = this.state;

    if (success) {
      return <Redirect to={`/news`} />;
    }

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/news', text: 'Новости' },
      { text: 'Добавление уведомления' }
    ];

    return (
      <Page title='Добавление уведомления' breadcrumbs={breadcrumbs}>
        <Transition>
          <H1>Добавление уведомления</H1>
          <NotifCreateForm
            onSuccess={this.onSuccess} />
        </Transition>
      </Page>
    );
  }
}
