import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import ContestDetail from '../../component/ContestDetail';
import { Spinner } from '../../firefly/component';

export default class ContestView extends Component {
  static propTypes = {
    contest: PropTypes.object,
    loading: PropTypes.bool,
    fetch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  static defaultProps = {
    user: null,
    loading: true,
    contest: null
  };

  UNSAFE_componentWillMount() {
    const {
      match,
      fetch
    } = this.props;

    fetch(match.params.id);
  }

  fetch() {
    const {
      match,
      fetch
    } = this.props;

    fetch(match.params.id);
  }

  UNSAFE_componentWillReceiveProps({ match, fetch }) {
    if (match.params.id !== this.props.match.params.id) {
      fetch(match.params.id);
    }
  }

  render() {
    const {
      loading,
      contest
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/contest', text: 'Конкурсы' },
      { icon: 'document', text: 'Детальный просмотр конкурса' }
    ];

    return (
      <Page title='Детальный просмотр конкурса' breadcrumbs={breadcrumbs}>
        {loading ? <Spinner /> : (
          <Transition>
            <ContestDetail
              onSuccess={() => this.fetch()}
              contest={contest} />
          </Transition>
        )}
      </Page>
    );
  }
}
