import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { H1 } from '../../firefly/component';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import CityTable from '../../component/CityTable';
import CountryTable from '../../component/CountryTable';
import PermissionContainer from '../../component/PermissionContainer';

export default class CityList extends Component {
  render() {
    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Города' }
    ];

    return (
      <Page title='Города' breadcrumbs={breadcrumbs}>
        <PermissionContainer>
        <Transition>
            <H1>Страны</H1>
            <div className="toolbar">
              <Link to="/country/create" className="b-button">
                Добавить страну
              </Link>
            </div>
            <CountryTable />
          </Transition>
          <Transition>
            <H1>Города</H1>
            <div className="toolbar">
              <Link to="/city/create" className="b-button">
                Добавить город
              </Link>
            </div>
            <CityTable />
          </Transition>
        </PermissionContainer>
      </Page>
    );
  }
}
