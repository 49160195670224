import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import ProductDetail from '../../component/ProductDetail';
import { Spinner } from '../../firefly/component';
import Uploader from '../../component/Uploader';
import ProductImageList from '../../component/ProductImageList';

export default class ProductView extends Component {
  static propTypes = {
    product: PropTypes.object,
    loading: PropTypes.bool,
    fetchImages: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  static defaultProps = {
    product: null,
    loading: true
  };

  UNSAFE_componentWillMount() {
    const {
      match,
      fetch
    } = this.props;

    fetch(match.params.id);
  }

  UNSAFE_componentWillReceiveProps({ match, fetch }) {
    if (match.params.id !== this.props.match.params.id) {
      fetch(match.params.id);
    }
  }

  render() {
    const {
      loading,
      product,
      fetchImages
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/product', text: 'Подарки' },
      { icon: 'document', text: 'Детальный просмотр подарка' }
    ];

    return (
      <Page title='Детальный просмотр подарка' breadcrumbs={breadcrumbs}>
        {loading ? <Spinner /> : (
          <Transition>
            <ProductDetail product={product} />
            <div className="divider" />
            <div>
              <Link
                className="b-button"
                to={`/product/update/${product.id}`}>
                Изменить
              </Link>
            </div>
            <div className="divider" >&nbsp;</div>
            <Uploader
              callback={() => fetchImages(product.id)}
              id={product.id} />
            <div className="divider" >&nbsp;</div>
            <ProductImageList product_id={product.id} />
          </Transition>
        )}
      </Page>
    );
  }
}
