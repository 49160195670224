import { bindConnect } from '../../utils';
import PartnerTable from './PartnerTable';
import * as partnerActions from '../../redux/actions/partner';
import * as notificationActions from '../../redux/actions/notifications';

export default bindConnect(state => ({
  loading: Boolean(state.partner_list.pending) || !state.partner_list.data,
  ...(state.partner_list.data || { meta: {}, objects: [] })
}), {
  remove: partnerActions.remove,
  fetch: partnerActions.list,
  notification: notificationActions.add
})(PartnerTable);
