import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { H1 } from '../../firefly/component';
import { Link } from 'react-router-dom';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import PartnerTable from '../../component/PartnerTable';
import PermissionContainer from '../../component/PermissionContainer';

export default class PartnerList extends Component {
  static propTypes = {
    filter: PropTypes.object.isRequired
  };

  render() {
    const {
      filter,
      siteId
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { text: 'Партнеры' }
    ];

    return (
      <Page title='Партнеры' breadcrumbs={breadcrumbs}>
        <PermissionContainer>
          <Transition>
            <H1>Партнеры</H1>
            <div className="toolbar">
              <Link to='/partner/create' className="b-button">
                Добавить партнера
              </Link>
            </div>
            <PartnerTable filter={filter} siteId={siteId} />
          </Transition>
        </PermissionContainer>
      </Page>
    );
  }
}
