import * as types from '../actionTypes';

export function doFetchPromocodeList(params) {
  return {
    type: types.PROMOCODE_LIST,
    request: { url: '/promocode/list', params }
  };
}

export function doPromocodeGenerate(data) {
  return {
    type: types.PROMOCODE_GENERATE,
    request: { url: '/promocode/generate', method: 'post', data }
  };
}

export function doPromocodeDownload(id) {
  return {
    type: types.PROMOCODE_DOWNLOAD,
    request: { url: `/promocode/qr/${id}` }
  };
}

export function doPromocodeExport() {
  return {
    type: types.PROMOCODE_EXPORT,
    request: { url: '/promocode/export' }
  };
}
