import { bindConnect } from '../../utils';
import CodeTable from './CodeTable';
import { doFetchCodeList } from '../../redux/actions/code';

export default bindConnect(state => ({
  loading: Boolean(state.code_list.pending) || !state.code_list.data,
  ...(state.code_list.data || { meta: {}, objects: [] })
}), {
  fetch: doFetchCodeList
})(CodeTable);
