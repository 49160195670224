import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import { Spinner } from '../../firefly/component';
import MonetaImgUploader from '../../component/MonetaImgUploader';
import MonetaImgsList from '../../component/MonetaImgsList';

export default class MonetaImgs extends Component {
  static propTypes = {
    moneta: PropTypes.object,
    loading: PropTypes.bool,
    fetchImages: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  static defaultProps = {
    moneta: null,
    loading: true
  };

  componentDidMount() {
    const {
      match,
      fetch
    } = this.props;

    fetch(match.params.id);
  }

  componentDidUpdate(prevProps) {
    const {
      loading,
      moneta,
      siteId,
      match
    } = this.props;

    if ((false === loading && null === moneta) || prevProps.siteId !== siteId) {
      if (match.params.id !== prevProps.match.params.id) {
        fetch(match.params.id);
      }  
    }
  }

  render() {
    const {
      loading,
      moneta,
      fetchImages
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/monets', text: 'Монеты' },
      { icon: 'document', text: 'Изображения монеты' }
    ];

    return (
      <Page title='Изображения монеты ' breadcrumbs={breadcrumbs}>
        {loading || moneta == null ? <Spinner /> : (
          <Transition>
            <div className="divider" />
            <div>{moneta.name} ({moneta.codename})</div>
            <div className="divider" >&nbsp;</div>
            <MonetaImgUploader
              callback={() => fetchImages(moneta.id)}
              id={moneta.id} />
            <div className="divider" >&nbsp;</div>
            <MonetaImgsList moneta_id={moneta.id} />
          </Transition>
        )}
      </Page>
    );
  }
}
