import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Pagination as Pager, Spinner } from '../../firefly/component';
import Table from '../Table';
import moment from 'moment/moment';
import { isGranted } from '../../utils';
import UserBalances from '../UserBalances';

export default class UserTable extends Component {
  static propTypes = {
    renderColumns: PropTypes.array,
    notification: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    admin: PropTypes.func.isRequired,
    partner: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    objects: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
    className: PropTypes.string,
    filtercity: PropTypes.string,
    filtercountry: PropTypes.string,
    filtergender: PropTypes.number,
    filterage: PropTypes.number,
  };

  static defaultProps = {
    renderColumns: [],
    className: '',
    filtercity: '',
    filtercountry: '',
    filtergender: -1,
    filterage: 0,
    sort: 0
  };

  constructor(props) {
    super(props);
    this.state = {
      filter: ''
    };
  }

  onPageChange = (page, page_size) => {
    this.fetch(page, page_size);
  };
  onPageSizeChange = page_size => {
    this.fetch(1, page_size);
  };
  onRemove = id => e => {
    e.preventDefault();

    const {
      remove,
      notification
    } = this.props;

    if (window.confirm('Удалить?')) {
      remove(id).then(() => {
        notification({ title: 'Удалено' });
        this.fetch();
      });
    }
  };  
  fetch = (page = 1, page_size = 10) => {
    const {
      siteId,
      filtercity,
      filtercountry,
      filtergender,
      filterage,
      sort
    } = this.props;

    const {
      filter,
    } = this.state;

    this.props.fetch({
      page_size,
      page,
      sort,
      filter,
      filtercity,
      filtercountry,
      filtergender,
      filterage,
      site_id: siteId
    });
  };
  toggleAdmin = user => e => {
    e.preventDefault();

    const {
      admin,
      notification
    } = this.props;

    admin(user.id, {
      value: !user.is_admin
    }).then(() => {
      notification({ title: 'Выполнено' });
      this.refresh();
    });
  };
  /*togglePartner = user => e => {
    e.preventDefault();

    const {
      partner,
      notification
    } = this.props;

    partner(user.id, {
      value: !user.is_partner
    }).then(() => {
      notification({ title: 'Выполнено' });
      this.refresh();
    });
  };*/

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      filtercity,
      filtercountry,
      filtergender,
      filterage,
      sort,
      siteId
    } = this.props;

    if ((false === loading && null === objects) ||
      prevProps.siteId !== siteId ||
      prevProps.filtercity !== filtercity ||
      prevProps.filtercountry !== filtercountry ||
      prevProps.filtergender !== filtergender ||
      prevProps.filterage !== filterage ||
      prevProps.sort !== sort
    ) {
      this.fetch();
    }
  }

  refresh() {
    this.fetch();
  }

  onFilter(e, filter) {
    e.preventDefault()
    this.setState({
      filter: filter
    })
    
    this.forceUpdate(() => this.fetch())
  }

  render() {
    const {
      renderColumns,
      className,
      loading,
      meta,
      objects,
      user,
      siteId,
      filtercity,
      filtercountry,
      filtergender,
      filterage
    } = this.props;

    if (loading) {
      return <Spinner />;
    }

    const columns = [
      {
        column: 'email',
        key: t => (
          <Link className="link" to={`/user/${t.id}`}>
            {t.partner != null ? t.emailPartner : t.email}
          </Link>
        ),
        title: 'Электронная почта'
      },
      {
        column: 'phone',
        key: t => (
          <Link className="link" to={`/user/${t.id}`}>
            +{t.phone_country}{t.phone}
          </Link>
        ),
        title: 'Телефон'
      },
      {
        key: x => x.countryname !== null ? x.countryname : <Icons.Minus />,
        title: 'Страна'
      },
      {
        key: x => x.cityname !== null ? x.cityname : <Icons.Minus />,
        title: 'Город'
      },
      {
        key: x => x.gender > 0 ? (x.gender === 1 ? 'М' : 'Ж') : <Icons.Minus />,
        title: 'Пол'
      },
      {
        key: x => x.date_birth !== null ? (((new Date().getTime() - new Date(x.date_birth)) / (24 * 3600 * 365.25 * 1000)) | 0) : <Icons.Minus />,
        title: 'Возраст'
      },
      isGranted(user, 'admin') && ({
        column: 'balance',
        key: t => (
          <div>
            <div>{t.Balance}</div>
            {t.userPartnerMoneta !== undefined ? 
              (<div>
                {t.userPartnerMoneta.map(function(element, i){
                  return (<div key={i}>{element.moneta.codename}: {element.amount}</div>)
                })}
              </div>)
              : <div/>
            }
          </div>
        ),
        title: 'Баланс'
      }),
    ]

    const columns2 = [
      {
        column: 'referals',
        key: 'referals',
        title: 'Рефералы'
      },
      {
        column: 'fio',
        key: t => t.fam+" "+t.name+" "+t.otch,
        title: 'ФИО'
      },
      isGranted(user, 'admin') && ({
        column: 'is_partner',
        key: t => t.emailPartner != null ? 
          (t.partner != null ? t.partner.name : '')
         : <></>,
        title: 'Партнер'
      }),
      isGranted(user, 'admin') && ({
        column: 'is_admin',
        key: t => (
          <button
            className={cx('toggle-link', { 'toggle-link--enabled': t.is_admin })}
            onClick={this.toggleAdmin(t)}>
            {t.is_admin ? (
              <Icons.Check />
            ) : (
              <Icons.X />
            )}
          </button>
        ),
        title: 'Администратор сервиса'
      }),
      {
        column: 'created_at',
        key: t => moment(t.created_at).format('lll'),
        title: 'Дата регистрации'
      },
      {
        column: 'last_activity',
        key: t => moment(t.last_activity).format('lll'),
        title: 'Последняя активность'
      },
      {
        column: 'is_active',
        key: t => t.is_active && <Icons.Check />,
        title: 'Активирован'
      },
      {
        column: 'actions',
        key: x => user.id !== x.id ? (
          <Fragment>
            <button onClick={this.onRemove(x.id)} className="link">
              <Icons.Trash2 className="icon" />
            </button>
          </Fragment>
        ) : (<></>),
        title: '',
        tdStyle: {
          align: 'right',
          width: 100,
          textAlign: 'center'
        }
      }
    ];

    if (isGranted(user, 'admin')) {
      meta.monets.map(function(element, i){        
        return columns.push({
          column: 'moneta'+element.id,
          key: t => (
              <div>{t.monet[element.id]}</div>
          ),
          title: element.codename,
        })
      });
    }

    return (
      <div>
        <Table
          renderColumns={renderColumns}
          className={className}
          columns={[...columns,...columns2]}
          data={objects}
        />
        {isGranted(user, 'admin') && (<UserBalances 
          siteId={siteId}
          filtercity={filtercity} filtercountry={filtercountry} filtergender={filtergender} filterage={filterage} 
          onFilter={(e, filter) => this.onFilter(e, filter)}
        />)}
        {!loading && <Pager
          onPageChange={this.onPageChange}
          pageCount={meta.page_count}
          page={meta.page}
          onPageSizeChange={this.onPageSizeChange}
          size={meta.page_size}
          total={meta.total}
        />}
      </div>
    );
  }
}
