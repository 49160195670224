import React, { Component } from 'react';
import { Pagination as Spinner } from '../../firefly/component';

export default class UserBalances extends Component {
  
  fetch = () => {
    const {
      siteId
    } = this.props;

    this.props.fetch({
      site_id: siteId
    });
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      loading,
      objects,
      siteId
    } = this.props;

    if ((false === loading && null === objects) || prevProps.siteId !== siteId) {
      this.fetch();
    }
  }

  render() {
    const {
      loading,
      objects
    } = this.props;

    if (loading) {
      return <Spinner />;
    }

    return (
      <div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{marginLeft: '10px'}}>Проявляли активность:</div>
          <div style={{marginLeft: '10px'}}>5 минут - {objects.count5},</div>
          <div style={{marginLeft: '10px'}}>1 час - {objects.count1},</div>
          <div style={{marginLeft: '10px'}}>24 часа - {objects.count24}</div>
        </div>
      </div>
    );
  }
}
