import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Transition from '../../component/Transition';
import Page from '../../component/Page';
import { Spinner } from '../../firefly/component';
import { H1 } from '../../firefly/component';

export default class PayDone extends Component {
  static propTypes = {
    data: PropTypes.object,
    loading: PropTypes.bool,
    fetch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
  };

  static defaultProps = {
    data: null,
    loading: true
  };

  UNSAFE_componentWillMount() {
    const {
      match,
      fetch
    } = this.props;

    fetch(match.params.id);
  }

  UNSAFE_componentWillReceiveProps({ match, fetch }) {
    if (match.params.id !== this.props.match.params.id) {
      fetch(match.params.id);
    }
  }

  render() {
    const {
      loading,
      data,
    } = this.props;

    const breadcrumbs = [
      { to: '/', icon: 'Home', text: 'Рабочий стол' },
      { to: '/licenses', text: 'Лицензии' },
      { icon: 'document', text: 'Завершение платежа' }
    ];
    console.log(data);

    return (
      <Page title='Завершение платежа' breadcrumbs={breadcrumbs}>
        {loading ? <Spinner /> : (
          <Transition>
            <H1>Платеж завершен</H1>
            <div>Баланс: {data.object.balance}</div>
            <div>Статус: {data.object.status}</div>
          </Transition>
        )}
      </Page>
    );
  }
}
